.logo_footer {
	img {
		width: 180px;
	}
}
.main_footer_listing {
	h6 {
		color: #fff;
		font-size: 18px;
		margin-bottom: 12px;
		font-weight: bold;
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			margin-bottom: 10px;
			a {
				color: #fff;
				font-size: 14px;
				&:hover {
					color: var(--main-color);
				}
			}
		}
	}
}
.main_footer_detail {
	p {
		font-size: 12px;
		color: #fff;
		margin: 0;
		padding: 10px 0 0;
		text-align: justify;
	}
}
.corporate_footer {
	h6 {
		font-size: 18px;
		color: #fff;
		font-weight: bold;
		margin: 0;
	}
	p {
		font-size: 14px;
		color: #fff;
		margin: 0;
	}
}
.main_detail_footer {
	padding-top: 10px;
	a {
		display: flex;
		font-size: 18px;
		color: #fff !important;
		font-weight: bold;
		margin: 0;
		span {
			i {
				padding-right: 10px;
				color: #fff;
			}
		}
		&:hover {
			color: var(--main-color);
		}
	}
}
.mages_detail_section {
	position: relative;
	input {
		width: 100%;
		padding: 5px;
		height: 40px;
		margin-top: 10px;
		border: 1px solid #ddd;
		border-radius: 10px !important;
		padding-left: 40px;
	}
	button {
		margin-top: 10px;
		background-color: var(--color-3);
		color: #fff;
		padding: 10px 0;
		border-radius: 10px;
		font-weight: bold;
		border: 0;
		cursor: pointer;
		width: 100%;
		&:hover {
			background-color: var(--main-color);
			color: #000;
		}
	}
}
.email-icon {
	position: absolute;
	top: 19px;
	left: 12px;
	color: #00318f;
}
.text-left {
	text-align: left;
}
.footer_bg_sec {
	background: url('../../../../../public/assets/images/footer_bg.png');
	width: 100%;
	background-size: 100% 100%;
	background-attachment: fixed;
    background-position: center;
}
.border_footer {
	border-top: 1px solid rgba(194, 194, 194, 1);
	padding: 10px 0;
	text-align: center;
}
.copy_right_main {
	a {
		font-size: 16px;
		color: #fff;
	}
}

// footer media
@media (max-width : 767px){
	.follow-us{
		margin-top: 10px;
	}
	.follow-us h6, ul{
		text-align: left !important;
	}
}
