.alcorn-contact-form-services .padding-b-t-50 {
  padding: 50px 0;
}
.alcorn-contact-form-services .padding-b-t-20 {
  padding: 20px 0;
}
.alcorn-contact-form-services .contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  box-shadow: -10px 12px 60px 14px rgb(156, 191, 232);
}
.alcorn-contact-form-services .contact-form .main_detail_services h2 {
  font-size: 35px;
  color: var(--color-3);
  font-weight: bold;
}
.alcorn-contact-form-services .contact-form .main_detail_services h2 span {
  color: rgb(3, 100, 5);
}
.alcorn-contact-form-services .contact-form .main_detail_services .pb-4 {
  padding-bottom: 1.5rem !important;
}
.alcorn-contact-form-services .contact-form .input_detail_sectrion {
  display: flex;
  flex-direction: column;
}
.alcorn-contact-form-services .contact-form .input_detail_sectrion input {
  margin-bottom: 28px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 5px 2px 0px rgba(193, 193, 193, 0.5803921569);
  height: 52px;
  font-size: 18px;
  border: 1px solid #ebebeb;
  font-weight: bold;
}
.alcorn-contact-form-services .contact-form .input_detail_sectrion textarea {
  margin-bottom: 28px;
  padding: 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 3px 2px 3px rgba(193, 193, 193, 0.5803921569);
  height: 100px;
  font-size: 16px;
  font-weight: bold;
}
.alcorn-contact-form-services .contact-form .input_detail_sectrion button {
  padding: 10px 20px;
  background-color: rgb(0, 82, 180);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
}
.alcorn-contact-form-services .contact-form .input_detail_sectrion button:hover {
  background-color: var(--main-color);
  color: #000;
}
.alcorn-contact-form-services .contact-form .input_detail_sectrion button span {
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  color: rgb(0, 82, 180);
  background: #fff;
}
.alcorn-contact-form-services .Registra_modul_Request {
  max-width: 800px;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content {
  border-radius: 0;
  padding: 20px;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail {
  text-align: center;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail h4 {
  font-size: 25px;
  color: var(--color-3);
  font-weight: 800;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail p {
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail .request_send {
  padding-top: 30px;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail .request_send .back_color_main {
  padding: 8px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-3);
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
  border: 0;
  max-width: 225px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail .request_send .back_color_main:hover {
  color: #fff;
  background-color: rgb(26, 147, 28);
}
.alcorn-contact-form-services .Registra_modul_Request .modal-content .thank_heading_detail .request_send .back_color_main span i {
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(26, 147, 28);
}/*# sourceMappingURL=ContactFormServices.css.map */