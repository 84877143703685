.logo_footer img {
  width: 180px;
}

.main_footer_listing h6 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: bold;
}
.main_footer_listing ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main_footer_listing ul li {
  margin-bottom: 10px;
}
.main_footer_listing ul li a {
  color: #fff;
  font-size: 14px;
}
.main_footer_listing ul li a:hover {
  color: var(--main-color);
}

.main_footer_detail p {
  font-size: 12px;
  color: #fff;
  margin: 0;
  padding: 10px 0 0;
  text-align: justify;
}

.corporate_footer h6 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  margin: 0;
}
.corporate_footer p {
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.main_detail_footer {
  padding-top: 10px;
}
.main_detail_footer a {
  display: flex;
  font-size: 18px;
  color: #fff !important;
  font-weight: bold;
  margin: 0;
}
.main_detail_footer a span i {
  padding-right: 10px;
  color: #fff;
}
.main_detail_footer a:hover {
  color: var(--main-color);
}

.mages_detail_section {
  position: relative;
}
.mages_detail_section input {
  width: 100%;
  padding: 5px;
  height: 40px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 10px !important;
  padding-left: 40px;
}
.mages_detail_section button {
  margin-top: 10px;
  background-color: var(--color-3);
  color: #fff;
  padding: 10px 0;
  border-radius: 10px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  width: 100%;
}
.mages_detail_section button:hover {
  background-color: var(--main-color);
  color: #000;
}

.email-icon {
  position: absolute;
  top: 19px;
  left: 12px;
  color: #00318f;
}

.text-left {
  text-align: left;
}

.footer_bg_sec {
  background: url("../../../../../public/assets/images/footer_bg.png");
  width: 100%;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
}

.border_footer {
  border-top: 1px solid rgb(194, 194, 194);
  padding: 10px 0;
  text-align: center;
}

.copy_right_main a {
  font-size: 16px;
  color: #fff;
}

@media (max-width: 767px) {
  .follow-us {
    margin-top: 10px;
  }
  .follow-us h6, ul {
    text-align: left !important;
  }
}/*# sourceMappingURL=Footer.css.map */