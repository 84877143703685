//Banner css
@import url(https://db.onlinewebfonts.com/c/bbb4a2324bd99e38040904f3983d9ba3?family=Geometr415+Blk+BT+Black);

.no-padding {
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'geometr415-blk-bt', sans-serif;
    ;
}

:root {
    --main-color: rgba(50, 251, 53, 1);
    --secondary-color: rgba(50, 251, 53, 1);
    --color-3: rgba(0, 48, 144, 1);
    ---color-4: rgba(22, 51, 0, 1);
}

a {
    text-decoration: none;
}




.alcorn-home-banner {


    .slider-items {
        position: relative;
        max-height: 700px;
        height: 100%;
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
    }

    .bg-img-1 {
        background-image: url('../../../../../public/assets/images/bannere.png');
    }

    .bg-img-2 {
        background-image: url('../../../../../public/assets/images/bannere.png');
    }

    .bg-img-3 {
        background-image: url('../../../../../public/assets/images/bannere.png');
    }

    .main-slider-theme {
        position: relative;

        .slick-arrow {
            display: none !important;
        }

        ul.slick-dots {
            text-align: center;
            margin-top: -70px;
            position: relative;
            z-index: 99;

            li {
                width: 25px;
                background: 0 0;
                color: inherit;
                border: none;
                display: inline-block;
                padding: 0 !important;
                font: inherit;

                button {
                    width: 15px;
                    height: 10px;
                    margin: 5px 7px;
                    background: rgba(217, 217, 217, 1);
                    display: block;
                    -webkit-backface-visibility: visible;
                    transition: opacity .2s ease;
                    border-radius: 30px;

                    &::before {
                        color: transparent;
                        opacity: 0;
                    }
                }
            }

            li.slick-active {
                background: 0 0;
                color: inherit;
                border: none;
                display: inline-block;
                padding: 0 !important;
                font: inherit;

                button {
                    background: var(--main-color);
                    width: 30px;
                    height: 10px;
                }
            }
        }

    }

    .slider-items:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .65
    }

    .main-slider-theme .owl-nav div {
        border: 2px solid #fff;
        color: #fff;
        height: 40px;
        width: 40px;
        position: absolute;
        z-index: 9;
        top: 50%;
        margin-top: -20px;
        left: 10px;
        line-height: 40px;
        text-align: center;
        -webkit-transition: linear .3s;
        -o-transition: linear .3s;
        transition: linear .3s;
    }

    .main-slider-theme .owl-nav div.owl-next {
        left: auto;
        right: 10px;
    }

    .main-slider-theme.owl-theme .owl-dots .owl-dot span {
        width: 15px;
        height: 10px;
        margin: 5px 7px;
        background: rgba(217, 217, 217, 1);
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity .2s ease;
        border-radius: 30px;
    }

    .main-slider-theme.owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background: var(--main-color);
        width: 30px;
        height: 10px;
    }

    .main-slider-theme .owl-nav {
        display: none;
    }

    .main-slider-theme .owl-dots {
        text-align: center;
        margin-top: -40px;
        position: relative;
        z-index: 99;
    }

    .pas_detail_section {
        padding-top: 200px;
        position: relative;
        z-index: 99;
        min-height: 830px;
        max-height: 100%;
    }

    .main_banner_headline {
        padding-top: 50px;
        padding-bottom: 50px;

        h3 {
            font-weight: bold;
            color: #fff;
            font-size: 55px;
        }

        h2 {
            font-size: 22px;
            font-family: 'Geometr415 Blk BT Black';
        }
    }

    .main_banner_headline span img {
        width: 50px !important;
        display: inline-block !important;
        height: 50px;
    }

    .main_banner_headline {
        font-size: 20px;
        color: #fff;
        font-family: 'Geometr415 Blk BT', sans-serif;
    }

    .main_banner_headline h3 {
        color: #fff;
        font-size: 60px;
    }

    .main_banner_headline h3 span {
        display: block;
        color: var(--main-color);
    }

    .main_banner_headline p {
        color: #fff;
        font-size: 16px;
        padding-right: 23vw;
        margin-bottom: 40px;
    }

    .main_nav_services {
        background: var(--main-color);
        padding: 12px 30px;
        border-radius: 21px;
        font-size: 15px;
        font-weight: 600;
        font-family: 'Geometr415 Blk BT', sans-serif;
        color: #fff;
        margin-right: 10px;
    }

    .main_nav_contact {
        background: rgba(127, 127, 127, 1);
        padding: 12px 30px;
        border-radius: 21px;
        font-size: 15px;
        font-weight: 600;
        font-family: 'Geometr415 Blk BT', sans-serif;
        color: #fff;
        margin-right: 10px;
    }

    .main_nav_services:hover,
    .main_nav_contact:hover {
        background: var(--color-3);
        color: #fff;
    }

    .dropdown-toggle::after {
        content: none !important;
    }
}
.vdbanner{
    height: -webkit-fill-available;
    object-fit: cover;
}

@media (max-width:767px) {
    .alcorn-home-banner .main_banner_headline p p{
        padding: 0px !important;
        max-height: 100px !important;
        overflow: auto;
        width: 100%;
    }
    .vdbanner{
        height: -webkit-fill-available;
        object-fit: cover;
    }
    .alcorn-home-banner{
        .main-slider-theme{
            ul.slick-dots{
                text-align: center !important;
            }
        }
    }
}

.chooseUsLogo img {
    width: 55px;
    object-fit: contain;
}

.alcorn-home-about {
    .padding-b-t-50 {
        padding: 90px 0;
    }

    .padding-b-50 {
        padding-bottom: 50px;
    }

    .padding-t-50 {
        padding-top: 50px;
    }

    .padding-b-t-20 {
        padding: 20px 0;
    }

    .padding-b-t-10 {
        padding: 20px 0 30px;
    }

    .adout_mainsection {
        position: relative;
    }

    .adout_mainsection {
        position: relative;
    }

    .main-image {
        width: 100%;
        max-width: 400px;
        max-height: 400px;
        height: auto;
        border-radius: 10px;
        object-fit: cover;
    }

    .inset-image {
        position: absolute;
        bottom: -20px;
        right: 20px;
        width: 45%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dot-pattern {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 200px;
        height: 200px;
        background-image: url('../../../../../public/assets/images/fillers.png');
        opacity: 0.5;
    }

    .about_section_detail {
        border-radius: 10px;
        padding: 20px;
        overflow: hidden;
        box-shadow: 0 1px 7px rgb(0 0 0 / 29%);
        background: #fff;
    }

    .about_section_detail h6 {
        color: var(--color-3);
        font-size: 30px;
        font-family: 'geometr415-blk-bt', sans-serif;
        font-weight: 600;
    }

    .about_section_detail h2 {
        color: var(---color-4);
        font-size: 34px;
        font-weight: 800;
        font-family: 'geometr415-blk-bt', sans-serif;
    }

    .about_section_detail p {
        font-size: 18px;
        color: #000;
        text-align: justify;
        list-style: none;
        // max-height: 200px;
        // overflow-y: auto;

    }

    .about_section_detail ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }

    .about_section_detail ul li {
        width: 50%;
        padding-bottom: 10px;
    }

    .main_about_more {
        padding: 20px 0 10px;
    }

    .main_about_more a {
        background: var(--main-color);
        padding: 12px 30px;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 600;
        font-family: 'geometr415-blk-bt', sans-serif;
        ;
        color: #000;
    }

    .main_about_more a:hover {
        background: var(--color-3);
        color: #fff;
    }

    .main_chek_icon img {
        width: 20px;
        margin-right: 10px;
    }

    li.who-we-are-list {
        p.who-we-are-list-points {
            text-align: unset;
            font-size: 18px;
        }
    }

}


.alcorn-home-why-choose-us {

    .padding-b-t-10 {
        padding: 20px 0 30px;
    }

    .bg_why_choose {
        background: linear-gradient(90deg, #fff 4%, #e2ffd7 80%);
    }

    .main_why_choose_us {
        text-align: center;
    }

    .main_why_choose_us h4 {
        font-size: 22px;
        color: rgba(3, 100, 5, 1);
        font-weight: 800;
        padding-bottom: 5px;

    }

    .main_why_choose_us h2 {
        font-size: 32px;
        color: var(--color-3);
        font-weight: 800;

    }

    .main_why_choose_us h2 span {
        color: rgba(3, 100, 5, 1);

    }

    .main_why_choose_us_new {
        text-align: center;
    }

    .main_why_choose_us_new h2 {
        font-size: 33px;
        color: var(--color-3);
        font-weight: 800;

    }

    .main_why_choose_us_new h4 {
        font-size: 22px;
        color: rgba(3, 100, 5, 1);
        font-weight: 600;
        padding-bottom: 5px;

    }

    .main_why_choose_us_new h2 span {
        color: rgba(3, 100, 5, 1);

    }

    .main_why_choose_us_new h4 a {
        color: #000;

    }

    .por_detail_section {
        max-height: 220px;
        min-height: 220px;
        border-radius: 10px;
        padding: 20px;
        overflow-y: auto;
        overflow: hidden;
        box-shadow: 0 1px 7px rgb(0 0 0 / 29%);
        position: relative;
        transition: .3s ease-in-out;
    }

    .por_detail_section span svg {
        width: 40px;
        height: 40px;
        z-index: 2;
        position: relative;
        color: rgba(3, 100, 5, 1);
    }

    .por_detail_section h3 {
        font-size: 22px;
        color: #000;
        font-weight: bold;
        padding: 10px 0 0;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    .por_detail_section p {

        font-size: 12px;
        color: #000;
        margin-bottom: 0;
        line-height: 22px;
        margin-top: 10px;
        z-index: 2;
    }

    .por_detail_section:hover h3 {
        color: #fff;
        position: relative;
        z-index: 2;
    }

    .por_detail_section:hover span img {
        color: #fff;
        position: relative;
        z-index: 2;
    }

    .por_detail_section:hover p {
        color: #fff;
        position: relative;
        z-index: 2;
    }

    .por_detail_section svg path {
        width: 50px;
        height: 45px;
    }

    .por_detail_section:hover svg path {
        fill: #fff;
    }

    .por_detail_section:hover .wrap-decription {
        visibility: visible;
        opacity: 1;
        transition: .3s ease-in-out;
    }

    .wrap-decription {
        position: absolute;
        top: -12px;
        bottom: 0;
        left: -12px;
        right: 0;
        border-radius: 10px;
        color: #fff;
        height: 16rem;
        visibility: visible;
        opacity: 0;
        z-index: 0;
        transition: .3s ease-in-out;
    }


}

// @media (max-width: 424) {
//     .alcorn-home-why-choose-us {
//         .wrap-decription {
//             left: -20px;

//             img {
//                 min-width: 100%;
//                 width: 440px !important;
//             }
//         }

//     }
// }

@media (max-width: 576px) {
    .wrap-decription {
        left: -25px !important;

        img {
            // min-width: 100%;
            width: 595px;
        }
    }
    .alcorn-home-banner .main_banner_headline p p{
        padding: 0px !important;
        max-height: 100px !important;
        overflow: auto;
        width: 100%;
    }
    .vdbanner{
        height: 450px;
        object-fit: cover;
    }
    .alcorn-home-banner .slider-items{
        height: 450px !important;
    }
}

@media (min-width:768px) and (max-width: 991px) {

   

    .wrap-decription {
        left: unset;

        img {
            width: 360px;
        }
    }
}


.alcorn-home-counter {
    .stats-section {
        background-color: var(---color-4);
        padding: 20px 0;
        color: white;
        text-align: center;
    }

    .stat-item {
        display: flex;
    }

    .stat-icon {
        width: 50px;
        height: 50px;
        background-color: var(--main-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-right: 10px;
    }

    .stat-icon img {
        max-width: 40px;
    }

    .main_counter {
        text-align: left;
    }

    .main_counter h4 {
        padding: 0;
        margin: 0;
        font-weight: bold;
    }

    .main_counter p {
        padding: 0;
        margin: 0;
    }
}

.alcorn-home-product {

    .main_Product_det {
        position: relative;
    }

    .main_Product_bg {
        background: linear-gradient(90deg, #efffe8 0%, #a8c0e5 100%);
    }

    .span_bg_section {
        position: absolute;
        top: 0;
        z-index: 0;
    }

    .span_bg_section img {
        width: 100%;
    }

    .main_Product_bg .container {
        position: relative;
        z-index: 9;
    }

    .main_Product_icon {
        color: var(--color-3);
        font-size: 25px;
        font-family: 'geometr415-blk-bt', sans-serif;
        font-weight: 600;
    }

    .header_text_main h2 {
        color: var(---color-4);
        font-size: 30px;
        font-weight: 800;
    }

    .header_text_main h2 span {
        display: block;
        color: var(--color-3);
    }

    .p_text_detail p {
        font-size: 14px;
        // text-align: left;
    }

    .bg_best_product_1 {
        background: url('../../../../../public/assets/images/Union_hover.png');
        background-size: 100% 100%;
        padding: 20px;
        text-align: center;
        min-height: 280px;
        max-height: 280px;
    }

    .bg_best_product_2 {
        background: url('../../../../../public/assets/images/Union_hover_1.png');
        background-size: 100% 100%;
        padding: 20px;
        text-align: center;
    }

    .bg_best_product:hover {
        background: url('../../../../../public/assets/images/Union.png');
        background-size: 100% 100%;

    }

    .bg_best_product h3 {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        padding: 10px 0 0;
        margin-bottom: 0;
    }

    .bg_best_product p {
        font-size: 14px;
        color: #fff;
        padding: 10px 0 0;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        /* Number of lines to show */
        // max-height: calc(1.2em * 4); /* Should match the line-height * number of lines */
        white-space: normal;
    }

    .bg_best_product_2 svg path {
        fill: #fff;
    }

    .bg_best_product_1 svg path {
        stroke: #fff;
    }

    .bg_best_product a {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        padding: 10px 0 0;
        margin-bottom: 0;
    }

    .justify-content-space-evenly {
        justify-content: space-evenly;
    }

    .bg_best_product_1:hover svg path {
        stroke: var(---color-4);
    }

    .bg_best_product_2:hover svg path {
        fill: var(---color-4);
    }

    .bg_best_product:hover p {
        color: var(---color-4);
    }

    .bg_best_product:hover h3 {
        color: var(---color-4);
    }

    .bg_best_product:hover a {
        color: var(---color-4);
    }


    // Services
    .alcorn-home-service {
        .main_bg_services {
            background: linear-gradient(90deg, #9ab7e1 15%, #9fcaca 50%);
        }

        .item-odd {
            margin-top: 75px;
        }

        .bg_best_services {
            background: url("../../../../../public/assets/images/Union.png");
            background-size: 100% 100%;
            padding: 20px;
            text-align: center;
            position: relative;
        }

        .bg_best_services svg {
            position: relative;
            z-index: 9;
        }

        .bg_best_services h3 {
            font-size: 16px;
            color: rgba(0, 0, 0, 1);
            font-weight: bold;
            padding: 10px 0 0;
            margin-bottom: 0;
            position: relative;
            z-index: 9;
        }

        .bg_best_services p {
            font-size: 14px;
            color: rgb(174, 174, 174);
            padding: 10px 0 0;
            margin-bottom: 0;
            position: relative;
            z-index: 9;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            /* line-height: 1.2; */
            /* max-height: 4.8em; */
            white-space: normal;
        }

        .bg_best_services p ul {
            padding-left: 0px;
        }

        .bg_best_services a {
            font-size: 14px;
            color: rgba(0, 80, 89, 1);
            font-weight: 500;
            padding: 10px 0 0;
            margin-bottom: 0;
            position: relative;
            z-index: 9;
        }

        .item-odd:hover .bg_best_services .wrap-decription {
            top: -3px;
        }

        .bg_best_services:hover .wrap-decription {
            visibility: visible;
            opacity: 1;
            transition: .3s ease-in-out;
        }

        .bg_best_services {
            background: url("../../../../../public/assets/images/Union.png");
            background-size: 100% 100%;
            padding: 20px;
            text-align: center;
            position: relative;
        }

        .wrap-decription img {
            width: 100%;
            height: 100%;
        }

        .bg_best_services svg {
            width: 70px;
        }

        .bg_best_services:hover .detail_svg path {
            fill: #fff;
        }

        .bg_best_services:hover .detail_stroke path {
            stroke: #fff;
        }

        .bg_best_services:hover h3 {
            color: #fff;
        }

        .bg_best_services:hover p {
            color: #fff;
        }

        .bg_best_services:hover a {
            color: #fff;
        }

        .bg_best_services .wrap-decription {
            position: absolute;
            top: 0px;
            left: 0;
            right: 0;
            border-radius: 10px;
            color: #fff;
            height: 16rem;
            visibility: visible;
            opacity: 0;
            z-index: 0;
            transition: .5s ease;
        }

        .main-slider-services {
            .slick-arrow {
                display: none !important;
            }

            .slick-list {
                .slick-track {
                    .bg_best_services {
                        margin-left: 10px;
                    }

                    // .slick-slide {
                    //     width: 264px !important;
                    //     margin-right: 20px !important;
                    // }
                }
            }
        }

    }
}


.alcorn-home-knowledge-more {
    .padding-b-t-20 {
        padding: 20px 0;
    }

    .main_Knowledge_more {
        background: linear-gradient(90deg, #e9fff0 0%, #e9fff0 100%);
    }

    .main_why_choose_us h4 a {
        color: #000;
    }

    .main_why_choose_us_new {
        text-align: center;
    }

    .main_why_choose_us_new h2 {
        font-size: 33px;
        color: var(--color-3);
        font-weight: 800;
    }

    .main_why_choose_us_new h4 {
        font-size: 22px;
        color: rgba(3, 100, 5, 1);
        font-weight: 600;
        padding-bottom: 5px;
    }

    .main_why_choose_us_new h4 a {
        color: #000;
    }

    /* ============= */
    .card-knowledge {
        // background: url("../../../../../public/assets/images/solar-panel.jpg") no-repeat center center / cover;
        border-radius: 10px;
        color: white;
        border: 0;
    }

    .card-content-knowledge {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }

    .card-content-knowledge h2 {
        margin: 0;
        font-size: 1.5em;
        color: #99cc33;
    }

    .contact-knowledge h4 {
        margin: 5px 0;
        font-size: 16px;
        font-weight: bold;
    }

    .card-content-knowledge p {
        margin: 10px 0;
        font-size: 1em;
    }

    .button-knowledge {
        display: inline-block;
        margin-top: 15px;
        padding: 10px 20px;
        font-size: 1em;
        color: #ffffff;
        background-color: #e74c3c;
        text-decoration: none;
        border-radius: 5px;
    }

    .contact-knowledge {
        background-color: #006666;
        padding: 15px;
        font-size: 0.9em;
        color: white;
        text-align: left;
        margin-top: 10px;
        border-radius: 0 0 10px 10px;
    }

    .contact-knowledge p {
        margin: 5px 0;
    }

    .contact-knowledge a {
        color: #fff;
        text-decoration: none;
    }


    .slick-slider {
        .slick-arrow {
            display: none;
        }

        .card-knowledge {
            margin-left: 10px;
        }
    }
}

.alcorn-home-we-can-help {
    .main_can_help {
        padding: 40px 0;
        background: url("../../../../../public/assets/images/can_help_bg.png");
        width: 100%;
        background-size: 100% 100%;
    }

    .justify-content-right {
        justify-content: right;
    }

    .main_why_can_help h3 {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
    }

    .main_why_can_help p {
        color: #fff;
        font-size: 16px;

        text-align: left;
    }

    .main_why_can_help a {
        display: inline-block;
        margin-top: 15px;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #000;
        background-color: var(--main-color);
        text-decoration: none;
        border-radius: 5px;
        min-width: 265px;
        text-align: center;
        margin-left: 10px;
    }

    .main_why_can_help a:hover {
        background-color: var(--color-3);
        color: #fff;
    }

    .main_why_can_help a span {
        padding-right: 10px;
    }
}

.alcorn-home-testimonial {
    .main_Testimonials {
        padding: 40px 0;
        background: url("../../../../../public/assets/images/testimonial-img.png");
        width: 100%;
        background-size: 100% 100%;
    }

    .main_why_testimonial {
        text-align: center;
    }

    .main_why_testimonial h2 {
        font-size: 33px;
        color: var(--color-3);
        font-weight: 800;
    }

    .main_why_testimonial h2 span {
        color: rgba(3, 100, 5, 1);
    }

    .main_why_testimonial h4 {
        font-size: 22px;
        color: rgba(3, 100, 5, 1);
        font-weight: 800;
        padding-bottom: 5px;
    }

    .card-testimonial {
        background: url("../../../../../public/assets/images/testimonial.png");
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: left;
        padding: 10px;
        position: relative;
        background-size: 100% 100%;
        margin-bottom: 50px;
        margin-left: 10px;

    }

    .card-testimonial h2 {
        word-break: break-all;
        margin: 0;
        font-size: 1.5em;
        color: #000;
        font-weight: bold;
    }

    .card-testimonial h3 {
        margin: 0;
        font-size: 1em;
        color: #666;
        margin-bottom: 15px;
    }

    .card-testimonial p {
        font-size: 0.9em;
        color: #777;
        margin-bottom: 15px;
        max-height: 100px;
        min-height: 100px;
        overflow-y: auto;
    }

    .stars-testimonial {
        color: rgb(0, 128, 0);
        margin-bottom: 15px;
        font-size: 19px;
    }

    .profile-pic-testimonial {
        border-radius: 50%;
        border: 3px solid white;
        width: 70px;
        height: 70px;
        position: absolute;
        bottom: -35px;
        right: 0;
    }

    .profile-pic-testimonial img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
    }

    .main-slider-testimonials {
        .slick-arrow {
            display: none !important;
        }

        // .slick-track {
        //     .slick-slide {
        //         width: 306px !important;
        //         margin-right: 20px !important;
        //     }
        // }
    }

}

.alcorn-home-help-and-support {

    .main_Support {
        padding: 40px 0;
        background: linear-gradient(90deg, #c4f5ff 0%, #baffae 100%);
    }

    .main_why_choose_us h4 {
        font-size: 22px;
        color: rgba(3, 100, 5, 1);
        font-weight: 800;
        padding-bottom: 5px;
    }

    .design_ul_list ul {
        padding: 20px 0 0;
        list-style: none;
        display: flex;
        justify-content: space-around;
    }

    .design_ul_list ul li a {
        font-size: 18px;
        font-weight: bold;
        color: var(--color-3);
    }

    .design_ul_list ul li a span {
        padding-right: 10px;
    }

    .card-support {
        margin-right: 10px;
        margin-top: 50px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        padding: 20px;
        position: relative;
        height: 220px;

    }

    .icon-support {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .icon-support img {
        width: 50px !important;
        height: 50px;
        padding: 5px;
    }

    .card-support h2 {
        margin-top: 30px;
        font-size: 20px;
        color: #000;
        font-weight: bold;
    }

    .card-support p {
        font-size: 0.9em;
        color: rgba(0, 81, 89, 1);
        margin-bottom: 15px;
    }

    .card-support a {
        display: inline-block;
        font-size: 1em;
        color: rgba(0, 80, 89, 1);
        text-decoration: none;

    }

}

.alcorn-home-contactus {
    .padding-b-t-20 {
        padding: 20px 0;
    }

    .contact-form {
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        box-shadow: -10px 12px 60px 14px rgb(156 191 232);
    }

    .input_detail_sectrion {
        display: flex;
        flex-direction: column;
    }

    .input_detail_sectrion input {
        margin-bottom: 20px;
        padding: 10px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        box-shadow: 0px 3px 8px 1px #c1c1c194;
        height: 55px;
        font-size: 18px;
        font-weight: bold;
    }

    .input_detail_sectrion textarea {
        margin-bottom: 20px;
        padding: 10px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        box-shadow: 0px 3px 8px 1px #c1c1c194;
        height: 100px;
        font-size: 16px;
        font-weight: bold;
    }

    .input_detail_sectrion button {
        padding: 10px 20px;
        background-color: rgba(0, 82, 180, 1);
        color: white;
        border: none;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;
        text-transform: uppercase;
    }

    .input_detail_sectrion button span {
        padding: 5px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        color: rgba(0, 82, 180, 1);
        background: #fff;
    }

    .detail_text_main p {
        font-size: 14px;
        color: rgba(0, 48, 144, 1);

    }

    .detail_text_main h2 {
        font-size: 35px;
        color: rgba(22, 51, 2, 1);
        font-weight: 700;
    }

    .info-con {
        text-align: left;
    }

    .info-item-con {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    .icon-con {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background-size: cover;
    }

    .phone-icon-con i {
        background: #fff;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: rgba(0, 82, 180, 1);
    }

    .location-icon-con i {
        background: #fff;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: rgba(0, 82, 180, 1);
        height: 45px;
        width: 45px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main_profile_con {
        display: flex;
    }

    .main_profile_con img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;

    }

    .location-icon-con {
        margin-right: 10px;
    }

    .teax_name_con h2 {
        font-size: 20px;
        font-weight: bold;
        color: #00318f;
    }

    .teax_name_con .email-con {
        font-size: 16px;
        font-weight: bold;
        color: rgba(3, 100, 5, 1);
    }

    .info-item-con a {
        display: flex;
        align-items: center;
    }

    .info-item-con a {
        color: rgba(0, 48, 144, 1);
        font-size: 16px;
    }

    .input_detail_sectrion button:hover {
        background-color: var(--main-color);
        color: #000;
    }

    /* ============modul============ */
    .Registra_modul_Request {
        max-width: 800px;
    }

    .Registra_modul_Request .modal-content {
        border-radius: 0;
        padding: 20px;
    }

    .back_color_main {
        text-align: center;
    }

    .thank_heading_detail {
        text-align: center;
    }

    .thank_heading_detail h4 {
        font-size: 25px;
        color: var(--color-3);
        font-weight: 800;
    }

    .thank_heading_detail p {
        font-size: 20px;
        color: #000;
        margin-bottom: 0;
    }

    .back_color_main {
        padding: 8px 25px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        background-color: var(--color-3);
        text-decoration: none;
        border-radius: 50px;
        text-align: center;
        border: 0;
        max-width: 225px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }

    .request_send {
        padding-top: 30px;
    }

    .back_color_main:hover {
        color: #fff;
        background-color: rgba(26, 147, 28, 1);
    }

    .back_color_main span i {
        background-color: #fff;
        border-radius: 100%;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(26, 147, 28, 1);
    }
}

.bg_blog_detail_home {
    background: url('../../../../../public/assets/images/blog_bg.png');
    width: 100%;
    background-size: 100% 100%;
    height: 200px;
    background-position: 0 0;
    background-repeat: no-repeat;

    h2 {
        margin: 5px 0;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin: 5px 0;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
    }

    h4 {
        margin: 5px 0;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
    }

    p {
        margin: 5px 0;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }
}

.main_detail_Knowledge {
    ul {
        border-bottom: 0 !important;
        justify-content: center;

        li {
            button {
                border: 0 solid transparent !important;
                border-radius: 0;
                background: transparent !important;
                font-size: 22px;
                color: #000;
                font-weight: 600;
                padding: 0;
                padding-right: 10px;

                &.active {
                    border: 0px solid #7290b9 !important;
                    border-radius: 0;
                    background: transparent !important;
                    font-size: 22px;
                    color: rgba(3, 100, 5, 1) !important;
                    font-weight: 600;
                }
            }
        }
    }
}

.card-content-knowledge {
    text-align: center;
}

.between_detail_sec {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
}

.stats-section {
    background-color: var(---color-4);
    padding: 20px 0;
    color: white;
    text-align: center;
}

.stat-item {
    display: flex;
}

.stat-icon {
    width: 50px;
    height: 50px;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 10px;

    img {
        max-width: 40px;
    }
}

.main_counter {
    text-align: left;

    h4 {
        padding: 0;
        margin: 0;
        font-weight: bold;
    }

    p {
        padding: 0;
        margin: 0;
    }
}

.youtube-video {
    cursor: pointer;
    height: 250px;
    width: 100%;
    position: relative;

    .youTubeIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 63px;
        height: 63px;
        transform: translate(-50%, -50%);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.data-scroll {
    max-height: 75px;
    overflow-y: auto;
}

.service-logo-svg {
    position: relative;
    z-index: 9;
    height: 80px;
    width: 80px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
        padding: 3px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
}

.text-right p {
    text-align: right;
}