.faq-section {
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }
    .faq_bg_detail {
        background: rgba(243, 245, 246, 1);
    }

    .main_faq_asked {
        max-width: 1000px;
        margin: 0 auto;

        .accordion-item {
            margin-bottom: 10px;
        }

        .accordion-collapse {
            padding: 10px;
        }
    }

    .asked_question {
        h4 {
            font-size: 32px;
            color: var(--color-3);
            font-weight: 800;
            text-align: center;
        }
    }

    .accordion-button {
        color: #000;
        background-color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;

        &:not(.collapsed) {
            color: #000;
            background-color: #fff;
            font-size: 16px;
        }

        &:focus {
            box-shadow: none;
        }

        &::after {
            filter: blur(.5px);
        }
    }

    .accordion-item {
        border: none;
    }

    .faq-answer{
        text-align: justify;
    }

    .terms-privacy-div{
        text-align: justify;
    }

}