.solar-lighting-section{
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }

    .banner_detail_text h2 {
        font-size: 45px;
        color: var(--color-3);
        font-weight: 800;
    }
    .bg_images_inverters_series {
        background: url('../../../../../public/assets/images/inverters_series/Background-Image.png');
        background-size: 100% 100%;
    }
    .s-right {
        text-align: right;
    }
    .banner_detail_text h2 span ,.main_detail_services h2 span{
        color: rgba(3, 100, 5, 1);
    }
    .main_detail_services ul li {
        padding-bottom: 18px;
        font-size: 16px;
    }
    .main_detail_services ul {
        padding: 20px 10px 0 18px;
    }
    .list_style_auto ul {
        list-style: auto;
    }
    .contact-form {
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        box-shadow: -10px 12px 60px 14px rgb(156 191 232);
    }
    
    .input_detail_sectrion {
        display: flex;
        flex-direction: column;
    }
    
    .input_detail_sectrion input{
        margin-bottom: 28px;
        padding: 10px;
        border-radius: 4px;
        width: 100%;
        box-shadow: 0px 5px 2px 0px #c1c1c194;
        height: 52px;
        font-size: 18px;
        border: 1px solid #ebebeb;
        font-weight: bold;
    }
    .input_detail_sectrion textarea {
        margin-bottom: 28px;
        padding: 10px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        box-shadow: 0px 3px 2px 3px #c1c1c194;
        height: 100px;
        font-size: 16px;
        font-weight: bold;
    }
    .input_detail_sectrion button {
        padding: 10px 20px;
        background-color: rgba(0, 82, 180, 1);
        color: white;
        border: none;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;
        text-transform: uppercase;
    }
    .input_detail_sectrion button span{
        padding: 5px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        color: rgba(0, 82, 180, 1);
        background: #fff;
    }
    .detail_text_main p{
        font-size: 14px;
        color: rgba(0, 48, 144, 1) ;
       
    }
    .detail_text_main h2{
        font-size: 35px;
        color: rgba(22, 51, 2, 1);
        font-weight: 700;
    } 
    .info-con {
        text-align: left;
    }
    
    .info-item-con {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }
    
    .icon-con {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background-size: cover;
    }
    
    .phone-icon-con i{
        background:#fff;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: rgba(0, 82, 180, 1);
    }
    
    .location-icon-con i{
        background:#fff;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: rgba(0, 82, 180, 1);
        height: 45px;
        width: 45px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main_profile_con{
        display: flex;
    }
    .main_profile_con img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
       
    }
    .location-icon-con{
        margin-right: 10px;
    }
    .teax_name_con h2{
        font-size: 20px;
        font-weight: bold;
        color: #00318f;
    }
    .teax_name_con .email-con{
        font-size: 16px;
        font-weight: bold;
        color: rgba(3, 100, 5, 1);
    }
    .info-item-con a{
        display: flex;
        align-items: center;
    }
    .info-item-con a{
        color:rgba(0, 48, 144, 1);
        font-size: 16px;
    }
    .input_detail_sectrion button:hover{
        background-color: var(--main-color);
        color: #000;
    }
    .main_detail_services h2 {
        font-size: 35px;
        color: var(--color-3);
        font-weight: bold;
    }
    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 100%);
        padding: 10px 0;
    }
    .slider-items-Bussiness {
        background: url('../../../../../public/assets/images/CapEx/Bussiness-Banner.png');
        width: 100%;
        background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
    }
    .slider-items-caPex {
        background: url('../../../../../public/assets/images/CapEx/Capex-Banner.png');
        width: 100%;
        background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
    }
    .bg_images_sales {
        background: url('../../../../../public/assets/images/Products/Div-section-3.png');
        background-size: 100% 100%;
    }
    .main_view_detail {
        border: 0;
        img {
            width: 100%;
            border-radius: 10px;
            max-height: 270px;
            background: #fff;
        }
    }
    .all_Information_main {
        border-bottom: 0 !important;
        border-bottom: 0 !important;
        display: flex;
        justify-content: space-between;
        button {
            width: 30%;
            border: 2px solid #ccc !important;
            padding: 6px 0 10px;
            border-radius: 5px;
            margin-top: 20px;
            img {
                width: 100%;
                max-width: 110px;
                height: 70px;
                margin: 0 auto;
            }
            &.active {
                border: 2px solid var(--color-3) !important;
                padding: 6px 0 10px;
                border-radius: 5px;
                margin-top: 20px;
            }
        }
    }
    .battery-product-detail-text {
        h4 {
            font-size: 32px;
            color: var(--color-3);
            font-weight: 800;
        }
        p {
            text-align: justify;
        }
    }
    .enquiry_button {
        padding: 12px 32px;
        margin-right: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: var(--color-3);
        text-decoration: none;
        border-radius: 10px;
        text-align: center;
        &:hover {
            background-color: var(--main-color);
        }
    }
    .store_button {
        padding: 12px 32px;
        margin-right: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: rgba(26, 147, 28, 1);
        text-decoration: none;
        border-radius: 10px;
        text-align: center;
        &:hover {
            background-color: var(--main-color);
        }
    }
    .download_now_button {
        a {
            padding: 12px 32px;
            font-size: 16px;
            font-weight: bold;
            color: var(--color-3);
            background-color: #fff;
            border: 3px solid var(--color-3);
            text-decoration: none;
            border-radius: 10px;
            text-align: center;
            margin-left: 5vw;
            &:hover {
                color: #fff;
                background-color: var(--color-3);
                border: 3px solid var(--color-3);
            }
        }
    }
    .main_bg_detail_inve {
        background: #f0f0f0;
        padding: 3px 0;
    }
    .main_detail_inveter {
        ul {
            border-bottom: 0 !important;
            li {
                button {
                    border: 2px solid transparent !important;
                    border-radius: 0;
                    background: transparent !important;
                    color: #7290b9;
                    font-weight: bold;
                    &.active {
                        border: 2px solid #7290b9 !important;
                        border-radius: 0;
                        background: transparent !important;
                        color: #7290b9 !important;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .detail_text_inveter {
        padding: 30px 0;
        ul {
            li {
                font-size: 16px;
                color: #000;
            }
        }
    }
    .main_border_feedback {
        border-top: 1px solid #000;
    }
    .over_sales_main {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-bottom: 10px;
    }
    .main_detail_sales {
        h3 {
            padding: 5px 0;
            font-size: 24px;
            color: var(--color-3);
            font-weight: bold;
            margin-bottom: 0;
        }
    }
    .main_drop_new_week {
        select {
            display: inline-block;
            width: 100%;
            font-weight: bold;
            font-size: 14px;
            line-height: 2.43;
            background-color: #e6e6e6;
            color: #000;
            border: 1px solid #e6e6e6 !important;
            text-align: left;
            padding: 10px;
            border-radius: 10px;
        }
    }
    .user-name {
        color: #818181;
        font-size: 20px;
    }
    .main_flex_div {
        display: flex;
        align-items: center;
    }
    .stars {
        i {
            font-size: 18px;
            color: rgba(255, 137, 1, 1) !important;
            padding-right: 4px;
        }
    }
    .text_detail_best {
        color: #000;
        font-size: 16px;
        font-weight: bold;
    }
    .review-text {
        font-size: 18px;
        color: #9f9f9f;
        margin: 5px 0;
    }
    .verified-seller {
        font-size: 16px;
    }
    .rounded-circle {
        width: 50px;
        height: 50px;
    }
    .align-items-center {
        align-items: center;
    }
    .main_textarea_feed {
        padding: 0 40px;
    }
    .main_detail_textarea {
        textarea {
            max-height: 200px;
            border-radius: 20px;
            padding: 20px;
            font-size: 20px;
            border: 2px solid #ccc;
            min-height: 200px;
            margin-bottom: 20px;
        }
        a {
            background: var(--color-3);
            padding: 14px 40px;
            border-radius: 16px;
            font-size: 17px;
            font-weight: 600;
            font-family: 'geometr415-blk-bt', sans-serif;
            color: #fff;
        }
    }
    .list_style_auto {
        ul {
            list-style: auto;
        }
    }
    
}