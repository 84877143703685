.inverter-list-section .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.inverter-list-section .banner_detail_text h2 {
  font-size: 45px;
  color: var(--color-3);
  font-weight: 800;
}
.inverter-list-section .banner_detail_text p {
  font-size: 18px;
  color: rgb(0, 49, 143);
  margin-bottom: 0;
}
.inverter-list-section .slider-items-Inverter {
  background: url("../../../../../../public/assets/images/inverters_series/Inverters-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.inverter-list-section .slider-items-Battery {
  background: url("../../../../../../public/assets/images/inverters_series/Battery-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.inverter-list-section .slider-items-Solar {
  background: url("../../../../../../public/assets/images/inverters_series/Solar-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.inverter-list-section .inverters_series_bg {
  background: #fff;
  padding: 40px 20px;
  margin-bottom: 50px;
  padding: 40px;
  margin: 0 0 40px;
}
.inverter-list-section .bg_images_inverters_series {
  background: url("../../../../../../public/assets/images/inverters_series/Background-Image.png");
  background-size: 100% 100%;
}
.inverter-list-section .srvices_images_porduct img {
  width: 100%;
  max-height: 318px;
  height: 100%;
  max-width: 318px;
}
.inverter-list-section .main_header_sosal.header_padd_20 {
  display: none;
}
.inverter-list-section .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.inverter-list-section .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}

.inverter-product-details .main_header_sosal.header_padd_20 {
  display: none;
}
.inverter-product-details .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.inverter-product-details .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.inverter-product-details .slider-items-Bussiness {
  background: url("../../../../../../public/assets/images/CapEx/Bussiness-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.inverter-product-details .slider-items-caPex {
  background: url("../../../../../../public/assets/images/CapEx/Capex-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.inverter-product-details .bg_images_sales {
  background: url("../../../../../../public/assets/images/Products/Div-section-3.png");
  background-size: 100% 100%;
}
.inverter-product-details .main_view_detail {
  border: 0;
}
.inverter-product-details .main_view_detail img {
  width: 100%;
  border-radius: 10px;
  max-height: 270px;
  background: #fff;
  -o-object-fit: contain;
     object-fit: contain;
}
.inverter-product-details .all_Information_main {
  border-bottom: 0 !important;
  border-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
}
.inverter-product-details .all_Information_main button {
  width: 30%;
  border: 2px solid #ccc !important;
  padding: 6px 0 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.inverter-product-details .all_Information_main button img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  max-width: 110px;
  height: 70px;
  margin: 0 auto;
}
.inverter-product-details .all_Information_main button.active {
  border: 2px solid var(--color-3) !important;
  padding: 6px 0 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.inverter-product-details .battery-product-detail-text h4 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
}
.inverter-product-details .battery-product-detail-text p {
  text-align: justify;
  word-break: break-all;
}
.inverter-product-details .enquiry_now_button {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 14px;
}
.inverter-product-details .enquiry_button {
  cursor: pointer;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-3);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
}
.inverter-product-details .enquiry_button:hover {
  background-color: var(--main-color);
}
.inverter-product-details .store_button {
  padding: 12px 32px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(26, 147, 28);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
}
.inverter-product-details .store_button:hover {
  background-color: var(--main-color);
}
.inverter-product-details .download_now_button a {
  padding: 12px 32px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-3);
  background-color: #fff;
  border: 3px solid var(--color-3);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
  margin-left: 5vw;
}
.inverter-product-details .download_now_button a:hover {
  color: #fff;
  background-color: var(--color-3);
  border: 3px solid var(--color-3);
}
.inverter-product-details .main_bg_detail_inve {
  background: #f0f0f0;
  padding: 3px 0;
}
.inverter-product-details .main_detail_inveter ul {
  border-bottom: 0 !important;
}
.inverter-product-details .main_detail_inveter ul li button {
  border: 2px solid transparent !important;
  border-radius: 0;
  background: transparent !important;
  color: #7290b9;
  font-weight: bold;
}
.inverter-product-details .main_detail_inveter ul li button.active {
  border: 2px solid #7290b9 !important;
  border-radius: 0;
  background: transparent !important;
  color: #7290b9 !important;
  font-weight: bold;
}
.inverter-product-details .detail_text_inveter {
  padding: 30px 0;
}
.inverter-product-details .detail_text_inveter ul li {
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
}
.inverter-product-details .main_border_feedback {
  border-top: 1px solid #000;
}
.inverter-product-details .over_sales_main {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
}
.inverter-product-details .main_detail_sales h3 {
  padding: 5px 0;
  font-size: 24px;
  color: var(--color-3);
  font-weight: bold;
  margin-bottom: 0;
}
.inverter-product-details .main_drop_new_week select {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.43;
  background-color: #e6e6e6;
  color: #000;
  border: 1px solid #e6e6e6 !important;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}
.inverter-product-details .user-name {
  color: #818181;
  font-size: 20px;
}
.inverter-product-details .main_flex_div {
  display: flex;
  align-items: center;
}
.inverter-product-details .stars i {
  font-size: 18px;
  padding-right: 4px;
}
.inverter-product-details .text_detail_best {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.inverter-product-details .review-text {
  font-size: 18px;
  color: #9f9f9f;
  margin: 5px 0;
  max-height: 140px;
  overflow-y: auto;
}
.inverter-product-details .verified-seller {
  font-size: 16px;
}
.inverter-product-details .rounded-circle {
  width: 50px;
  height: 50px;
}
.inverter-product-details .align-items-center {
  align-items: center;
}
.inverter-product-details .main_textarea_feed {
  padding: 0 40px;
}
.inverter-product-details .main_detail_textarea .submit-button {
  background: var(--color-3);
  padding: 10px 36px;
  border-radius: 16px;
  font-size: 17px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
  cursor: pointer;
  border: none;
}
.inverter-product-details .main_detail_textarea textarea {
  max-height: 200px;
  border-radius: 20px;
  padding: 20px;
  font-size: 20px;
  border: 2px solid #ccc;
  min-height: 200px;
  margin-bottom: 20px;
}
.inverter-product-details .main_detail_textarea a {
  background: var(--color-3);
  padding: 14px 40px;
  border-radius: 16px;
  font-size: 17px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
}
.inverter-product-details .list_style_auto ul {
  list-style: auto;
}
.inverter-product-details .feedback-input {
  border-radius: 10px;
  border: 2px solid #C0C0C0;
  margin-bottom: 10px;
}
.inverter-product-details .rating h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 0px;
  color: #000000;
}
.inverter-product-details .rating .fa-star {
  color: #ccc; /* Color for empty stars */
}
.inverter-product-details .rating .fa-star.filled {
  color: gold; /* Color for filled stars */
}
@media screen and (min-width: 992px) {
  .inverter-product-details .border_lg_right {
    border-right: 1px solid #000;
  }
}/*# sourceMappingURL=OnGridSolar.css.map */