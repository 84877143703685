
.store-locator{
    .main_header_sosal.header_padd_20{
        display: none;
    }
    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }

    .slider-store-locator {
        // background: url('../../../../../public/assets/images/store_locator/banner.png');
        width: 100%;
        background-size: 100% 100%;
        min-height: 400px;
        max-height: 400px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
    }
    .main_store_locator {
        text-align: center;
        padding-top: 130px;
        a {
            background: var(--color-3);
            padding: 10px 45px;
            border-radius: 21px;
            font-size: 20px;
            font-weight: 600;
            font-family: 'geometr415-blk-bt', sans-serif;
            color: #fff;
            border: 0;
            &:hover {
                background: var(--main-color);
                color: #000;
            }
        }
        h2 {
            font-size: 32px;
            font-weight: 800;
            margin-bottom: 10px;
        }
        input {
            font-size: 18px;
            margin-bottom: 10px;
            height: 55px;
            max-width: 400px;
            margin: 20px auto;
        }
    }
    .main_stor_locator {
        justify-content: flex-end;
    }
    .main_detail_section {
        h3 {
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 0;
        }
    }
    .map_detail_main {
        padding: 20px;
        background: rgba(241, 241, 241, 1);
    }
    .main_detail_locetion {
        background: #fff;
        padding: 20px;
        h3 {
            font-size: 30px;
            font-weight: 700;
            color: #000;
            padding: 0;
            small {
                font-size: 12px;
                font-weight: normal;
                color: #000;
                float: right;
                padding: 0;
            }
        }
        p {
            font-size: 14px;
            font-weight: normal;
            padding: 0;
        }
        a {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            color: #00318f;
        }
    }
    .searchStore{
        background: var(--color-3);
        padding: 8px 40px;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 600;
        font-family: "geometr415-blk-bt", sans-serif;
        color: #fff;
        border: 0;
        width: fit-content;
        margin: auto;
        cursor: pointer;
    }
    .locetion_button {
        display: flex;
        justify-content: start;
        // align-items: center;
        gap: 10px;
        margin-top: 20px;
        a {
            &:hover {
                background: var(--main-color) !important;
                color: #000 !important;
                border: 1px solid  var(--main-color) !important;
            }
        }
    }
    .locetion_button_first {
        padding: 8px 20px;
        font-size: 16px;
        color: #000 !important;
        border: 1px solid #000;
        border-radius: 20px;
        margin-right: 20px;
    }
    .locetion_button_dir {
        padding: 8px 20px;
        font-size: 16px;
        color: #fff !important;
        border: 1px solid var(--color-3);
        background: var(--color-3);
        border-radius: 20px;
        margin-right: 20px;
    }
    .main_locetion_detail {
        height: 100%;
    }
    
}    
@media screen and (max-width: 991px) {
    .store-locator .main_store_locator{
        padding-top: 91px !important;
    }
}