.all-services .left-section {
  flex-direction: row-reverse;
}
.all-services .left-section .s-left {
  text-align: left;
}
.all-services .right-section .s-right {
  text-align: right;
}
.all-services .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.all-services .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.all-services .main_header_sosal.header_padd_20 {
  display: none;
}
.all-services .slider-items-srvices {
  background: url("../../../../../public/assets/images/srvices/srvices.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 500px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.all-services .bg_images_se {
  background: url("../../../../../public/assets/images/srvices/Background-Image.png");
  background-size: 100% 100%;
}
.all-services .services_payra p {
  font-size: 16px;
  color: 15px;
}
.all-services .main_detail_services h2 {
  font-size: 35px;
  color: var(--color-3);
  font-weight: bold;
}
.all-services .main_detail_services h2 span {
  color: rgb(3, 100, 5);
}
.all-services .main_detail_services ul {
  padding: 20px 10px 0 18px;
}
.all-services .main_detail_services ul li {
  padding-bottom: 18px;
  font-size: 16px;
}
.all-services .main_detail_services a {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(26, 147, 28);
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
}
.all-services .main_detail_services a:hover {
  color: #fff;
  background-color: var(--color-3);
}
.all-services .main_detail_services a span i {
  margin-left: 10px;
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(26, 147, 28);
}
.all-services .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.all-services .banner_detail_text h2 {
  font-size: 45px;
  color: var(--color-3);
  font-weight: 800;
}
.all-services .banner_detail_text h2 span {
  color: rgb(3, 100, 5);
}
.all-services .banner_detail_text p {
  font-size: 18px;
  color: rgb(0, 49, 143);
  margin-bottom: 0;
}
.all-services .srvices_images img {
  width: 100%;
  max-height: 400px;
  height: 100%;
  max-width: 400px;
}
.all-services .s-right {
  text-align: right;
}
.all-services .s-left {
  text-align: left;
}
.all-services .b-right img {
  border-radius: 0 75px 0 0;
}
.all-services .b-left img {
  border-radius: 75px 0 0 0;
}

.capex-solar-project .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.capex-solar-project .banner_detail_text h2 {
  font-size: 45px;
  color: var(--color-3);
  font-weight: 800;
}
.capex-solar-project .bg_images_inverters_series {
  background: url("../../../../../public/assets/images/inverters_series/Background-Image.png");
  background-size: 100% 100%;
}
.capex-solar-project .banner_detail_text h2 span {
  color: rgb(3, 100, 5);
}
.capex-solar-project .main_detail_services h2 {
  font-size: 35px;
  color: var(--color-3);
  font-weight: bold;
}
.capex-solar-project .main_detail_services h2 span {
  color: rgb(3, 100, 5);
}
.capex-solar-project .main_detail_services ul li {
  padding-bottom: 18px;
  font-size: 16px;
}
.capex-solar-project .list_style_auto ul {
  list-style: auto;
}
.capex-solar-project .contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  box-shadow: -10px 12px 60px 14px rgb(156, 191, 232);
}
.capex-solar-project .input_detail_sectrion {
  display: flex;
  flex-direction: column;
}
.capex-solar-project .input_detail_sectrion input {
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 5px 2px 0px rgba(193, 193, 193, 0.5803921569);
  height: 52px;
  font-size: 18px;
  border: 1px solid #ebebeb;
  font-weight: bold;
}
.capex-solar-project .input_detail_sectrion textarea {
  margin-bottom: 28px;
  padding: 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 3px 2px 3px rgba(193, 193, 193, 0.5803921569);
  height: 100px;
  font-size: 16px;
  font-weight: bold;
}
.capex-solar-project .input_detail_sectrion button {
  padding: 10px 20px;
  background-color: rgb(0, 82, 180);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
}
.capex-solar-project .input_detail_sectrion button span {
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  color: rgb(0, 82, 180);
  background: #fff;
}
.capex-solar-project .detail_text_main p {
  font-size: 14px;
  color: rgb(0, 48, 144);
}
.capex-solar-project .detail_text_main h2 {
  font-size: 35px;
  color: rgb(22, 51, 2);
  font-weight: 700;
}
.capex-solar-project .info-con {
  text-align: left;
}
.capex-solar-project .info-item-con {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.capex-solar-project .icon-con {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-size: cover;
}
.capex-solar-project .phone-icon-con i {
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #ccc;
  color: rgb(0, 82, 180);
}
.capex-solar-project .location-icon-con i {
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #ccc;
  color: rgb(0, 82, 180);
  height: 45px;
  width: 45px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.capex-solar-project .main_profile_con {
  display: flex;
}
.capex-solar-project .main_profile_con img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.capex-solar-project .location-icon-con {
  margin-right: 10px;
}
.capex-solar-project .teax_name_con h2 {
  font-size: 20px;
  font-weight: bold;
  color: #00318f;
}
.capex-solar-project .teax_name_con .email-con {
  font-size: 16px;
  font-weight: bold;
  color: rgb(3, 100, 5);
}
.capex-solar-project .info-item-con a {
  display: flex;
  align-items: center;
}
.capex-solar-project .info-item-con a {
  color: rgb(0, 48, 144);
  font-size: 16px;
}
.capex-solar-project .input_detail_sectrion button:hover {
  background-color: var(--main-color);
  color: #000;
}
.capex-solar-project .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.capex-solar-project .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.capex-solar-project .main_header_sosal.header_padd_20 {
  display: none;
}
.capex-solar-project .slider-items-Bussiness {
  background: url("../../../../../public/assets/images/CapEx/Bussiness-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.capex-solar-project .slider-items-caPex {
  background: url("../../../../../public/assets/images/CapEx/Capex-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.capex-solar-project .bg_images_sales {
  background: url("../../../../../public/assets/images/Products/Div-section-3.png");
  background-size: 100% 100%;
}
.capex-solar-project .main_view_detail {
  border: 0;
}
.capex-solar-project .main_view_detail img {
  width: 100%;
  border-radius: 10px;
  max-height: 270px;
  background: #fff;
}
.capex-solar-project .all_Information_main {
  border-bottom: 0 !important;
  border-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
}
.capex-solar-project .all_Information_main button {
  width: 30%;
  border: 2px solid #ccc !important;
  padding: 6px 0 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.capex-solar-project .all_Information_main button img {
  width: 100%;
  max-width: 110px;
  height: 70px;
  margin: 0 auto;
}
.capex-solar-project .all_Information_main button.active {
  border: 2px solid var(--color-3) !important;
  padding: 6px 0 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.capex-solar-project .battery-product-detail-text h4 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
}
.capex-solar-project .battery-product-detail-text p {
  text-align: justify;
}
.capex-solar-project .enquiry_button {
  padding: 12px 32px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-3);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
}
.capex-solar-project .enquiry_button:hover {
  background-color: var(--main-color);
}
.capex-solar-project .store_button {
  padding: 12px 32px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(26, 147, 28);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
}
.capex-solar-project .store_button:hover {
  background-color: var(--main-color);
}
.capex-solar-project .download_now_button a {
  padding: 12px 32px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-3);
  background-color: #fff;
  border: 3px solid var(--color-3);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
  margin-left: 5vw;
}
.capex-solar-project .download_now_button a:hover {
  color: #fff;
  background-color: var(--color-3);
  border: 3px solid var(--color-3);
}
.capex-solar-project .main_bg_detail_inve {
  background: #f0f0f0;
  padding: 3px 0;
}
.capex-solar-project .main_detail_inveter ul {
  border-bottom: 0 !important;
}
.capex-solar-project .main_detail_inveter ul li button {
  border: 2px solid transparent !important;
  border-radius: 0;
  background: transparent !important;
  color: #7290b9;
  font-weight: bold;
}
.capex-solar-project .main_detail_inveter ul li button.active {
  border: 2px solid #7290b9 !important;
  border-radius: 0;
  background: transparent !important;
  color: #7290b9 !important;
  font-weight: bold;
}
.capex-solar-project .detail_text_inveter {
  padding: 30px 0;
}
.capex-solar-project .detail_text_inveter ul li {
  font-size: 16px;
  color: #000;
}
.capex-solar-project .main_border_feedback {
  border-top: 1px solid #000;
}
.capex-solar-project .over_sales_main {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
}
.capex-solar-project .main_detail_sales h3 {
  padding: 5px 0;
  font-size: 24px;
  color: var(--color-3);
  font-weight: bold;
  margin-bottom: 0;
}
.capex-solar-project .main_drop_new_week select {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.43;
  background-color: #e6e6e6;
  color: #000;
  border: 1px solid #e6e6e6 !important;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}
.capex-solar-project .user-name {
  color: #818181;
  font-size: 20px;
}
.capex-solar-project .main_flex_div {
  display: flex;
  align-items: center;
}
.capex-solar-project .stars i {
  font-size: 18px;
  color: rgb(255, 137, 1) !important;
  padding-right: 4px;
}
.capex-solar-project .text_detail_best {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.capex-solar-project .review-text {
  font-size: 18px;
  color: #9f9f9f;
  margin: 5px 0;
}
.capex-solar-project .verified-seller {
  font-size: 16px;
}
.capex-solar-project .rounded-circle {
  width: 50px;
  height: 50px;
}
.capex-solar-project .align-items-center {
  align-items: center;
}
.capex-solar-project .main_textarea_feed {
  padding: 0 40px;
}
.capex-solar-project .main_detail_textarea textarea {
  max-height: 200px;
  border-radius: 20px;
  padding: 20px;
  font-size: 20px;
  border: 2px solid #ccc;
  min-height: 200px;
  margin-bottom: 20px;
}
.capex-solar-project .main_detail_textarea a {
  background: var(--color-3);
  padding: 14px 40px;
  border-radius: 16px;
  font-size: 17px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
}
.capex-solar-project .list_style_auto ul {
  list-style: auto;
}

.capex-model .thank_heading_detail {
  text-align: center;
}
.capex-model .request_send {
  padding-top: 30px;
}
.capex-model .back_color_main span i {
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(26, 147, 28);
}
.capex-model .Registra_modul_Request {
  max-width: 800px;
}
.capex-model .thank_heading_detail h4 {
  font-size: 25px;
  color: var(--color-3);
  font-weight: 800;
}
.capex-model .thank_heading_detail p {
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}
.capex-model .back_color_main {
  padding: 8px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-3);
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
  border: 0;
  max-width: 225px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.s-right {
  text-align: right;
}

@media screen and (max-width: 991px) {
  .all-services .banner_detail_text h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .all-services .services_payra p {
    font-size: 14px;
    line-height: 22px;
  }
}/*# sourceMappingURL=services.css.map */