.download-section .main_header_sosal.header_padd_20 {
  display: none;
}
.download-section .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.download-section .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.download-section .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.download-section .banner_detail_text h2 {
  font-size: 45px;
  color: var(--color-3);
  font-weight: 800;
}
.download-section .main_detail_services h2 {
  font-size: 35px;
  color: var(--color-3);
  font-weight: bold;
}
.download-section .slider-items-download {
  background: url("../../../../../public/assets/images/download/Catalogue-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.download-section .main_detail_catalogue {
  margin-bottom: 10px;
  background: #fff;
  box-shadow: 1px 0px 7px 0px #cbcbcb;
}
.download-section .main_detail_catalogue:hover {
  background: #e7e7e7;
  box-shadow: 1px 0px 7px 0px #cbcbcb;
}
.download-section .catalogue_detail_img {
  padding: 20px 30px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-section .catalogue_detail_img img {
  width: 100%;
  height: 100%;
}
.download-section .main_solar_det {
  padding: 10px 0;
  background-color: rgb(0, 82, 180);
  color: #fff;
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}
.download-section .main_solar_det span {
  padding-left: 10px;
}
.download-section .main_solar_det span i {
  font-size: 26px;
}/*# sourceMappingURL=Download.css.map */