.no-padding {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "geometr415-blk-bt", sans-serif;
}

:root {
  --main-color: rgba(50, 251, 53, 1);
  --secondary-color: rgba(50, 251, 53, 1);
  --color-3: rgba(0, 48, 144, 1);
  ---color-4: rgba(22, 51, 0, 1);
}

a {
  text-decoration: none;
}

.header-alcorn {
  /* ==============sosal icon============== */
  /* ==============sosal icon============== */
}
.header-alcorn .bg-primary-black {
  background: #fff;
  border-radius: 10px;
}
.header-alcorn .bg-primary-black .header_border_xs .nav-item .dropdown-toggle::after {
  display: none !important;
}
.header-alcorn .bg_traprent_fix.sticky {
  background: #00318f;
  padding-bottom: 10px;
}
.header-alcorn .bg_traprent_fix.sticky .bg-primary-black {
  padding: 0;
}
.header-alcorn .bg_traprent_fix.sticky .logo_main img {
  width: 110px;
}
.header-alcorn .bg_traprent_fix.sticky .header_padd_20 {
  padding: 4px 0;
}
.header-alcorn .bg_traprent_fix.sticky .not-home-page {
  padding: 5px 0 !important;
}
.header-alcorn .but_start_header {
  display: inline-block;
  border-radius: 6px;
  border: 2px solid var(--main-color);
  background: var(--main-color);
  color: var(--color-3);
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 12px;
  font-size: 15px;
  letter-spacing: 1px;
}
.header-alcorn .but_start_header:hover {
  border: 2px solid var(--secondary-color);
  background: var(--secondary-color);
}
.header-alcorn .logo_main img {
  width: 150px;
}
.header-alcorn .header_padd_20 {
  padding: 15px 0;
}
.header-alcorn .not-home-page {
  padding: 0px 0 !important;
}
.header-alcorn .sosal_email_icon a {
  color: #fff;
  font-size: 16px;
  padding-right: 10px;
}
.header-alcorn .sosal_mid_icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.header-alcorn .sosal_mid_icon ul li {
  display: inline-block;
  padding: 0 5px;
}
.header-alcorn .sosal_mid_icon ul li a i {
  color: #fff;
  font-size: 22px;
}
.header-alcorn .sosal_mid_icon {
  text-align: right;
}
.header-alcorn .navbar-nav .nav-link {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}
.header-alcorn .dropend .dropdown-toggle {
  color: var(--color-3);
  margin-left: 1em;
}
.header-alcorn .dropdown:hover > .dropdown-menu,
.header-alcorn .dropend:hover > .dropdown-menu {
  margin-top: 0.125em;
  margin-left: 0.125em;
}
.header-alcorn .bg_traprent_fix {
  background: transparent;
  position: fixed;
  z-index: 999;
  width: 100%;
}
.header-alcorn .navbar-collapse {
  flex-grow: unset;
}
.header-alcorn .main_nav_button a {
  background: var(--color-3);
  padding: 12px 30px !important;
  border-radius: 16px;
  font-size: 15px !important;
  font-weight: 600 !important;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff !important;
}
.header-alcorn .main_nav_button a:hover {
  background: var(--main-color);
  color: #fff;
}
.header-alcorn .main_nav_button a i {
  display: none;
}
.header-alcorn .bg_detail_drop {
  background: rgb(137, 234, 95);
}
.header-alcorn .icon_drop_sec {
  float: right;
  font-size: 13px;
  line-height: 2;
}
.header-alcorn .dropend .dropdown-toggle {
  color: #333;
  font-size: 16px;
  font-weight: normal;
  padding: 0 0 7px;
}
.header-alcorn .dropend .dropdown-toggle:hover {
  color: var(--color-3);
}
.header-alcorn .bg_detail_drop .dropdown-item:hover {
  color: var(--color-3);
  background: transparent;
}
@media screen and (min-width: 1200px) {
  .header-alcorn .header_border_xs li {
    padding: 0 10px;
  }
}
@media screen and (min-width: 992px) {
  .header-alcorn {
    /* new */
    /* new */
  }
  .header-alcorn .bg-primary-black {
    background: #fff;
    border-radius: 10px;
    padding: 5px 0;
  }
  .header-alcorn .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .header-alcorn .bg_detail_drop {
    width: -moz-max-content;
    width: max-content;
    background: rgb(137, 234, 95);
    border-radius: 12px;
    border: 0;
    top: 100%;
    left: 0;
  }
}
@media screen and (max-width: 991px) {
  .header-alcorn {
    /* new */
    /* new */
  }
  .header-alcorn .head_xs_i {
    transform: rotateZ(90deg);
  }
  .header-alcorn .header_border_xs li a i {
    float: right;
  }
  .header-alcorn .main_banner_headline h3 {
    font-size: 40px;
  }
  .header-alcorn .stat-item {
    margin-bottom: 15px;
  }
  .header-alcorn .stat-item {
    margin-bottom: 15px;
  }
  .header-alcorn .bg_detail_drop {
    padding-top: 0px;
    background: transparent;
    border: 0;
  }
  .header-alcorn .bg_detail_drop li a {
    color: #fff;
  }
  .header-alcorn .por_detail_section {
    margin-bottom: 15px;
  }
  .header-alcorn .bg_traprent_fix {
    background: #345ba6;
    position: sticky;
  }
  .header-alcorn .main_nav_button a {
    background: transparent;
    padding-left: 0px !important;
  }
  .header-alcorn .bg-primary-black {
    background: transparent;
    padding: 0;
  }
  .header-alcorn .main_nav_button a span {
    display: none;
  }
  .header-alcorn .main_nav_button a i {
    display: block;
    font-size: 30px;
  }
  .header-alcorn .pas_detail_section {
    padding-top: 0;
  }
  .header-alcorn .slider-items {
    max-height: 700px;
    height: 100%;
  }
  .header-alcorn .navbar-nav .nav-link {
    color: #fff;
  }
  .header-alcorn .bg_traprent_fix {
    background: #82b7e3 !important;
  }
  .header-alcorn .bg_traprent_fix.sticky {
    background: #82b7e3 !important;
  }
}
@media screen and (min-width: 768px) {
  .header-alcorn .main_why_choose_us h2 {
    font-size: 33px;
  }
  .header-alcorn .dropend .dropdown-toggle {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
@media screen and (max-width: 767px) {
  .header-alcorn .slider-items-srvices {
    min-height: 58vw;
  }
  .header-alcorn .main_detail_services {
    margin-top: 20px;
  }
  .header-alcorn .srvices_images {
    text-align: center;
  }
  .header-alcorn .about_section_detail {
    margin-top: 40px;
  }
}
@media screen and (max-width: 576px) {
  .header-alcorn .sosal_email_icon,
  .header-alcorn .sosal_mid_icon {
    text-align: center;
  }
  .header-alcorn .main_why_choose_us h2 {
    font-size: 25px;
  }
  .header-alcorn .bg_best_product_1 {
    margin-bottom: 15px;
  }
  .header-alcorn .header_padd_20 {
    padding: 3px 0;
  }
  .header-alcorn .sosal_email_icon {
    padding-bottom: 10px;
  }
  .header-alcorn .por_detail_section {
    margin-bottom: 15px;
  }
  .header-alcorn .main_banner_headline h3 {
    color: #fff;
    font-size: 28px;
  }
}
@media screen and (max-width: 480px) {
  .header-alcorn .main_nav_services {
    padding: 12px 10px;
  }
  .header-alcorn .main_nav_contact {
    padding: 12px 15px;
  }
}

@media screen and (max-width: 575px) {
  .header-alcorn .sosal_email_icon {
    display: grid;
  }
  .alcorn-home-why-choose-us .main_why_choose_us h2 {
    font-size: 25px !important;
  }
  .alcorn-home-why-choose-us .por_detail_section h3 {
    font-size: 16px !important;
  }
  .alcorn-home-about .about_section_detail ul li {
    width: 100% !important;
  }
  .alcorn-home-about .about_section_detail ul {
    flex-direction: column;
  }
  .alcorn-home-about .about_section_detail h2 {
    font-size: 26px !important;
  }
  .alcorn-home-about .about_section_detail h6 {
    font-size: 20px !important;
  }
  .alcorn-home-banner .main_nav_services, .alcorn-home-banner .main_nav_contact {
    font-size: 14px !important;
    padding: 8px 12px !important;
  }
  .alcorn-home-banner .slider-items {
    background-size: cover !important;
  }
  .alcorn-home-banner .pas_detail_section {
    padding-top: 14px !important;
    padding-bottom: 20px;
  }
  .alcorn-home-banner .main_banner_headline p {
    padding-right: 0px;
  }
  .header-alcorn .logo_main img {
    width: 125px;
  }
  .main_banner_headline h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .header-alcorn .sosal_mid_icon ul li a i {
    font-size: 16px;
  }
  .header-alcorn .sosal_email_icon a {
    font-size: 12px;
  }
}
.noScroll {
  overflow: hidden !important;
}
.noScroll::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.2470588235);
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .dnoneresp {
    display: block !important;
  }
  .dnoneresp-mob {
    display: none !important;
  }
}
@media screen and (min-width: 992px) {
  .dnoneresp {
    display: none !important;
  }
  .dnoneresp-mob {
    display: block !important;
  }
}/*# sourceMappingURL=Header.css.map */