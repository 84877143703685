.alcorn-opex-solar-project {
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }


    .slider-items-caPex {
        background: url("../../../../../public/assets/images/CapEx/Capex-Banner.png");
        width: 100%;
        background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;

        .banner_detail_text {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translate(0, -50%);
            background: linear-gradient(90deg, #fff 0%, transparent 100%);
            padding: 10px 0;

            h2 {
                font-size: 45px;
                color: var(--color-3);
                font-weight: 800;

                span {
                    color: rgba(3, 100, 5, 1);
                }
            }
        }


    }

    .padding-b-t-50 {
        padding: 50px 0;
    }

    .padding-b-t-20 {
        padding: 20px 0;
    }

    .bg_images_inverters_series {
        background: url("../../../../../public/assets/images/inverters_series/Background-Image.png");
        background-size: 100% 100%;

        .main_detail_services {
            h2 {
                font-size: 35px;
                color: var(--color-3);
                font-weight: bold;

                span {
                    color: rgba(3, 100, 5, 1);
                }
            }
        }

        .s-right {
            text-align: right;
        }

        .b-right {
            img {
                border-radius: 0 75px 0 0;
            }
        }

        .srvices_images {
            img {
                width: 100%;
                max-height: 400px;
                height: 100%;
                max-width: 400px;
            }
        }

        .list_style_auto {
            ul {
                list-style: auto;
            }
        }

        .main_detail_services {
            ul {
                padding: 20px 10px 0 18px;

                li {
                    padding-bottom: 18px;
                    font-size: 16px;
                }
            }
        }
    }

}