@import url(https://db.onlinewebfonts.com/c/bbb4a2324bd99e38040904f3983d9ba3?family=Geometr415+Blk+BT+Black);
.no-padding {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "geometr415-blk-bt", sans-serif;
}

:root {
  --main-color: rgba(50, 251, 53, 1);
  --secondary-color: rgba(50, 251, 53, 1);
  --color-3: rgba(0, 48, 144, 1);
  ---color-4: rgba(22, 51, 0, 1);
}

a {
  text-decoration: none;
}

.alcorn-home-banner .slider-items {
  position: relative;
  max-height: 700px;
  height: 100%;
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.alcorn-home-banner .bg-img-1 {
  background-image: url("../../../../../public/assets/images/bannere.png");
}
.alcorn-home-banner .bg-img-2 {
  background-image: url("../../../../../public/assets/images/bannere.png");
}
.alcorn-home-banner .bg-img-3 {
  background-image: url("../../../../../public/assets/images/bannere.png");
}
.alcorn-home-banner .main-slider-theme {
  position: relative;
}
.alcorn-home-banner .main-slider-theme .slick-arrow {
  display: none !important;
}
.alcorn-home-banner .main-slider-theme ul.slick-dots {
  text-align: center;
  margin-top: -70px;
  position: relative;
  z-index: 99;
}
.alcorn-home-banner .main-slider-theme ul.slick-dots li {
  width: 25px;
  background: 0 0;
  color: inherit;
  border: none;
  display: inline-block;
  padding: 0 !important;
  font: inherit;
}
.alcorn-home-banner .main-slider-theme ul.slick-dots li button {
  width: 15px;
  height: 10px;
  margin: 5px 7px;
  background: rgb(217, 217, 217);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.alcorn-home-banner .main-slider-theme ul.slick-dots li button::before {
  color: transparent;
  opacity: 0;
}
.alcorn-home-banner .main-slider-theme ul.slick-dots li.slick-active {
  background: 0 0;
  color: inherit;
  border: none;
  display: inline-block;
  padding: 0 !important;
  font: inherit;
}
.alcorn-home-banner .main-slider-theme ul.slick-dots li.slick-active button {
  background: var(--main-color);
  width: 30px;
  height: 10px;
}
.alcorn-home-banner .slider-items:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
}
.alcorn-home-banner .main-slider-theme .owl-nav div {
  border: 2px solid #fff;
  color: #fff;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 9;
  top: 50%;
  margin-top: -20px;
  left: 10px;
  line-height: 40px;
  text-align: center;
  transition: linear 0.3s;
}
.alcorn-home-banner .main-slider-theme .owl-nav div.owl-next {
  left: auto;
  right: 10px;
}
.alcorn-home-banner .main-slider-theme.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 10px;
  margin: 5px 7px;
  background: rgb(217, 217, 217);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.alcorn-home-banner .main-slider-theme.owl-theme .owl-dots .owl-dot.active span,
.alcorn-home-banner .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-color);
  width: 30px;
  height: 10px;
}
.alcorn-home-banner .main-slider-theme .owl-nav {
  display: none;
}
.alcorn-home-banner .main-slider-theme .owl-dots {
  text-align: center;
  margin-top: -40px;
  position: relative;
  z-index: 99;
}
.alcorn-home-banner .pas_detail_section {
  padding-top: 200px;
  position: relative;
  z-index: 99;
  min-height: 830px;
  max-height: 100%;
}
.alcorn-home-banner .main_banner_headline {
  padding-top: 50px;
  padding-bottom: 50px;
}
.alcorn-home-banner .main_banner_headline h3 {
  font-weight: bold;
  color: #fff;
  font-size: 55px;
}
.alcorn-home-banner .main_banner_headline h2 {
  font-size: 22px;
  font-family: "Geometr415 Blk BT Black";
}
.alcorn-home-banner .main_banner_headline span img {
  width: 50px !important;
  display: inline-block !important;
  height: 50px;
}
.alcorn-home-banner .main_banner_headline {
  font-size: 20px;
  color: #fff;
  font-family: "Geometr415 Blk BT", sans-serif;
}
.alcorn-home-banner .main_banner_headline h3 {
  color: #fff;
  font-size: 60px;
}
.alcorn-home-banner .main_banner_headline h3 span {
  display: block;
  color: var(--main-color);
}
.alcorn-home-banner .main_banner_headline p {
  color: #fff;
  font-size: 16px;
  padding-right: 23vw;
  margin-bottom: 40px;
}
.alcorn-home-banner .main_nav_services {
  background: var(--main-color);
  padding: 12px 30px;
  border-radius: 21px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Geometr415 Blk BT", sans-serif;
  color: #fff;
  margin-right: 10px;
}
.alcorn-home-banner .main_nav_contact {
  background: rgb(127, 127, 127);
  padding: 12px 30px;
  border-radius: 21px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Geometr415 Blk BT", sans-serif;
  color: #fff;
  margin-right: 10px;
}
.alcorn-home-banner .main_nav_services:hover,
.alcorn-home-banner .main_nav_contact:hover {
  background: var(--color-3);
  color: #fff;
}
.alcorn-home-banner .dropdown-toggle::after {
  content: none !important;
}

.vdbanner {
  height: -webkit-fill-available;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 767px) {
  .alcorn-home-banner .main_banner_headline p p {
    padding: 0px !important;
    max-height: 100px !important;
    overflow: auto;
    width: 100%;
  }
  .vdbanner {
    height: -webkit-fill-available;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .alcorn-home-banner .main-slider-theme ul.slick-dots {
    text-align: center !important;
  }
}
.chooseUsLogo img {
  width: 55px;
  -o-object-fit: contain;
     object-fit: contain;
}

.alcorn-home-about .padding-b-t-50 {
  padding: 90px 0;
}
.alcorn-home-about .padding-b-50 {
  padding-bottom: 50px;
}
.alcorn-home-about .padding-t-50 {
  padding-top: 50px;
}
.alcorn-home-about .padding-b-t-20 {
  padding: 20px 0;
}
.alcorn-home-about .padding-b-t-10 {
  padding: 20px 0 30px;
}
.alcorn-home-about .adout_mainsection {
  position: relative;
}
.alcorn-home-about .adout_mainsection {
  position: relative;
}
.alcorn-home-about .main-image {
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  height: auto;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.alcorn-home-about .inset-image {
  position: absolute;
  bottom: -20px;
  right: 20px;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.alcorn-home-about .dot-pattern {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 200px;
  height: 200px;
  background-image: url("../../../../../public/assets/images/fillers.png");
  opacity: 0.5;
}
.alcorn-home-about .about_section_detail {
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.29);
  background: #fff;
}
.alcorn-home-about .about_section_detail h6 {
  color: var(--color-3);
  font-size: 30px;
  font-family: "geometr415-blk-bt", sans-serif;
  font-weight: 600;
}
.alcorn-home-about .about_section_detail h2 {
  color: var(---color-4);
  font-size: 34px;
  font-weight: 800;
  font-family: "geometr415-blk-bt", sans-serif;
}
.alcorn-home-about .about_section_detail p {
  font-size: 18px;
  color: #000;
  text-align: justify;
  list-style: none;
}
.alcorn-home-about .about_section_detail ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.alcorn-home-about .about_section_detail ul li {
  width: 50%;
  padding-bottom: 10px;
}
.alcorn-home-about .main_about_more {
  padding: 20px 0 10px;
}
.alcorn-home-about .main_about_more a {
  background: var(--main-color);
  padding: 12px 30px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #000;
}
.alcorn-home-about .main_about_more a:hover {
  background: var(--color-3);
  color: #fff;
}
.alcorn-home-about .main_chek_icon img {
  width: 20px;
  margin-right: 10px;
}
.alcorn-home-about li.who-we-are-list p.who-we-are-list-points {
  text-align: unset;
  font-size: 18px;
}

.alcorn-home-why-choose-us .padding-b-t-10 {
  padding: 20px 0 30px;
}
.alcorn-home-why-choose-us .bg_why_choose {
  background: linear-gradient(90deg, #fff 4%, #e2ffd7 80%);
}
.alcorn-home-why-choose-us .main_why_choose_us {
  text-align: center;
}
.alcorn-home-why-choose-us .main_why_choose_us h4 {
  font-size: 22px;
  color: rgb(3, 100, 5);
  font-weight: 800;
  padding-bottom: 5px;
}
.alcorn-home-why-choose-us .main_why_choose_us h2 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
}
.alcorn-home-why-choose-us .main_why_choose_us h2 span {
  color: rgb(3, 100, 5);
}
.alcorn-home-why-choose-us .main_why_choose_us_new {
  text-align: center;
}
.alcorn-home-why-choose-us .main_why_choose_us_new h2 {
  font-size: 33px;
  color: var(--color-3);
  font-weight: 800;
}
.alcorn-home-why-choose-us .main_why_choose_us_new h4 {
  font-size: 22px;
  color: rgb(3, 100, 5);
  font-weight: 600;
  padding-bottom: 5px;
}
.alcorn-home-why-choose-us .main_why_choose_us_new h2 span {
  color: rgb(3, 100, 5);
}
.alcorn-home-why-choose-us .main_why_choose_us_new h4 a {
  color: #000;
}
.alcorn-home-why-choose-us .por_detail_section {
  max-height: 220px;
  min-height: 220px;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.29);
  position: relative;
  transition: 0.3s ease-in-out;
}
.alcorn-home-why-choose-us .por_detail_section span svg {
  width: 40px;
  height: 40px;
  z-index: 2;
  position: relative;
  color: rgb(3, 100, 5);
}
.alcorn-home-why-choose-us .por_detail_section h3 {
  font-size: 22px;
  color: #000;
  font-weight: bold;
  padding: 10px 0 0;
  margin-bottom: 0;
  text-transform: capitalize;
}
.alcorn-home-why-choose-us .por_detail_section p {
  font-size: 12px;
  color: #000;
  margin-bottom: 0;
  line-height: 22px;
  margin-top: 10px;
  z-index: 2;
}
.alcorn-home-why-choose-us .por_detail_section:hover h3 {
  color: #fff;
  position: relative;
  z-index: 2;
}
.alcorn-home-why-choose-us .por_detail_section:hover span img {
  color: #fff;
  position: relative;
  z-index: 2;
}
.alcorn-home-why-choose-us .por_detail_section:hover p {
  color: #fff;
  position: relative;
  z-index: 2;
}
.alcorn-home-why-choose-us .por_detail_section svg path {
  width: 50px;
  height: 45px;
}
.alcorn-home-why-choose-us .por_detail_section:hover svg path {
  fill: #fff;
}
.alcorn-home-why-choose-us .por_detail_section:hover .wrap-decription {
  visibility: visible;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.alcorn-home-why-choose-us .wrap-decription {
  position: absolute;
  top: -12px;
  bottom: 0;
  left: -12px;
  right: 0;
  border-radius: 10px;
  color: #fff;
  height: 16rem;
  visibility: visible;
  opacity: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

@media (max-width: 576px) {
  .wrap-decription {
    left: -25px !important;
  }
  .wrap-decription img {
    width: 595px;
  }
  .alcorn-home-banner .main_banner_headline p p {
    padding: 0px !important;
    max-height: 100px !important;
    overflow: auto;
    width: 100%;
  }
  .vdbanner {
    height: 450px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .alcorn-home-banner .slider-items {
    height: 450px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .wrap-decription {
    left: unset;
  }
  .wrap-decription img {
    width: 360px;
  }
}
.alcorn-home-counter .stats-section {
  background-color: var(---color-4);
  padding: 20px 0;
  color: white;
  text-align: center;
}
.alcorn-home-counter .stat-item {
  display: flex;
}
.alcorn-home-counter .stat-icon {
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
}
.alcorn-home-counter .stat-icon img {
  max-width: 40px;
}
.alcorn-home-counter .main_counter {
  text-align: left;
}
.alcorn-home-counter .main_counter h4 {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.alcorn-home-counter .main_counter p {
  padding: 0;
  margin: 0;
}

.alcorn-home-product .main_Product_det {
  position: relative;
}
.alcorn-home-product .main_Product_bg {
  background: linear-gradient(90deg, #efffe8 0%, #a8c0e5 100%);
}
.alcorn-home-product .span_bg_section {
  position: absolute;
  top: 0;
  z-index: 0;
}
.alcorn-home-product .span_bg_section img {
  width: 100%;
}
.alcorn-home-product .main_Product_bg .container {
  position: relative;
  z-index: 9;
}
.alcorn-home-product .main_Product_icon {
  color: var(--color-3);
  font-size: 25px;
  font-family: "geometr415-blk-bt", sans-serif;
  font-weight: 600;
}
.alcorn-home-product .header_text_main h2 {
  color: var(---color-4);
  font-size: 30px;
  font-weight: 800;
}
.alcorn-home-product .header_text_main h2 span {
  display: block;
  color: var(--color-3);
}
.alcorn-home-product .p_text_detail p {
  font-size: 14px;
}
.alcorn-home-product .bg_best_product_1 {
  background: url("../../../../../public/assets/images/Union_hover.png");
  background-size: 100% 100%;
  padding: 20px;
  text-align: center;
  min-height: 280px;
  max-height: 280px;
}
.alcorn-home-product .bg_best_product_2 {
  background: url("../../../../../public/assets/images/Union_hover_1.png");
  background-size: 100% 100%;
  padding: 20px;
  text-align: center;
}
.alcorn-home-product .bg_best_product:hover {
  background: url("../../../../../public/assets/images/Union.png");
  background-size: 100% 100%;
}
.alcorn-home-product .bg_best_product h3 {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding: 10px 0 0;
  margin-bottom: 0;
}
.alcorn-home-product .bg_best_product p {
  font-size: 14px;
  color: #fff;
  padding: 10px 0 0;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  /* Number of lines to show */
  white-space: normal;
}
.alcorn-home-product .bg_best_product_2 svg path {
  fill: #fff;
}
.alcorn-home-product .bg_best_product_1 svg path {
  stroke: #fff;
}
.alcorn-home-product .bg_best_product a {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding: 10px 0 0;
  margin-bottom: 0;
}
.alcorn-home-product .justify-content-space-evenly {
  justify-content: space-evenly;
}
.alcorn-home-product .bg_best_product_1:hover svg path {
  stroke: var(---color-4);
}
.alcorn-home-product .bg_best_product_2:hover svg path {
  fill: var(---color-4);
}
.alcorn-home-product .bg_best_product:hover p {
  color: var(---color-4);
}
.alcorn-home-product .bg_best_product:hover h3 {
  color: var(---color-4);
}
.alcorn-home-product .bg_best_product:hover a {
  color: var(---color-4);
}
.alcorn-home-product .alcorn-home-service .main_bg_services {
  background: linear-gradient(90deg, #9ab7e1 15%, #9fcaca 50%);
}
.alcorn-home-product .alcorn-home-service .item-odd {
  margin-top: 75px;
}
.alcorn-home-product .alcorn-home-service .bg_best_services {
  background: url("../../../../../public/assets/images/Union.png");
  background-size: 100% 100%;
  padding: 20px;
  text-align: center;
  position: relative;
}
.alcorn-home-product .alcorn-home-service .bg_best_services svg {
  position: relative;
  z-index: 9;
}
.alcorn-home-product .alcorn-home-service .bg_best_services h3 {
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  padding: 10px 0 0;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
}
.alcorn-home-product .alcorn-home-service .bg_best_services p {
  font-size: 14px;
  color: rgb(174, 174, 174);
  padding: 10px 0 0;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* line-height: 1.2; */
  /* max-height: 4.8em; */
  white-space: normal;
}
.alcorn-home-product .alcorn-home-service .bg_best_services p ul {
  padding-left: 0px;
}
.alcorn-home-product .alcorn-home-service .bg_best_services a {
  font-size: 14px;
  color: rgb(0, 80, 89);
  font-weight: 500;
  padding: 10px 0 0;
  margin-bottom: 0;
  position: relative;
  z-index: 9;
}
.alcorn-home-product .alcorn-home-service .item-odd:hover .bg_best_services .wrap-decription {
  top: -3px;
}
.alcorn-home-product .alcorn-home-service .bg_best_services:hover .wrap-decription {
  visibility: visible;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.alcorn-home-product .alcorn-home-service .bg_best_services {
  background: url("../../../../../public/assets/images/Union.png");
  background-size: 100% 100%;
  padding: 20px;
  text-align: center;
  position: relative;
}
.alcorn-home-product .alcorn-home-service .wrap-decription img {
  width: 100%;
  height: 100%;
}
.alcorn-home-product .alcorn-home-service .bg_best_services svg {
  width: 70px;
}
.alcorn-home-product .alcorn-home-service .bg_best_services:hover .detail_svg path {
  fill: #fff;
}
.alcorn-home-product .alcorn-home-service .bg_best_services:hover .detail_stroke path {
  stroke: #fff;
}
.alcorn-home-product .alcorn-home-service .bg_best_services:hover h3 {
  color: #fff;
}
.alcorn-home-product .alcorn-home-service .bg_best_services:hover p {
  color: #fff;
}
.alcorn-home-product .alcorn-home-service .bg_best_services:hover a {
  color: #fff;
}
.alcorn-home-product .alcorn-home-service .bg_best_services .wrap-decription {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  border-radius: 10px;
  color: #fff;
  height: 16rem;
  visibility: visible;
  opacity: 0;
  z-index: 0;
  transition: 0.5s ease;
}
.alcorn-home-product .alcorn-home-service .main-slider-services .slick-arrow {
  display: none !important;
}
.alcorn-home-product .alcorn-home-service .main-slider-services .slick-list .slick-track .bg_best_services {
  margin-left: 10px;
}

.alcorn-home-knowledge-more {
  /* ============= */
}
.alcorn-home-knowledge-more .padding-b-t-20 {
  padding: 20px 0;
}
.alcorn-home-knowledge-more .main_Knowledge_more {
  background: linear-gradient(90deg, #e9fff0 0%, #e9fff0 100%);
}
.alcorn-home-knowledge-more .main_why_choose_us h4 a {
  color: #000;
}
.alcorn-home-knowledge-more .main_why_choose_us_new {
  text-align: center;
}
.alcorn-home-knowledge-more .main_why_choose_us_new h2 {
  font-size: 33px;
  color: var(--color-3);
  font-weight: 800;
}
.alcorn-home-knowledge-more .main_why_choose_us_new h4 {
  font-size: 22px;
  color: rgb(3, 100, 5);
  font-weight: 600;
  padding-bottom: 5px;
}
.alcorn-home-knowledge-more .main_why_choose_us_new h4 a {
  color: #000;
}
.alcorn-home-knowledge-more .card-knowledge {
  border-radius: 10px;
  color: white;
  border: 0;
}
.alcorn-home-knowledge-more .card-content-knowledge {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.alcorn-home-knowledge-more .card-content-knowledge h2 {
  margin: 0;
  font-size: 1.5em;
  color: #99cc33;
}
.alcorn-home-knowledge-more .contact-knowledge h4 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
}
.alcorn-home-knowledge-more .card-content-knowledge p {
  margin: 10px 0;
  font-size: 1em;
}
.alcorn-home-knowledge-more .button-knowledge {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  color: #ffffff;
  background-color: #e74c3c;
  text-decoration: none;
  border-radius: 5px;
}
.alcorn-home-knowledge-more .contact-knowledge {
  background-color: #006666;
  padding: 15px;
  font-size: 0.9em;
  color: white;
  text-align: left;
  margin-top: 10px;
  border-radius: 0 0 10px 10px;
}
.alcorn-home-knowledge-more .contact-knowledge p {
  margin: 5px 0;
}
.alcorn-home-knowledge-more .contact-knowledge a {
  color: #fff;
  text-decoration: none;
}
.alcorn-home-knowledge-more .slick-slider .slick-arrow {
  display: none;
}
.alcorn-home-knowledge-more .slick-slider .card-knowledge {
  margin-left: 10px;
}

.alcorn-home-we-can-help .main_can_help {
  padding: 40px 0;
  background: url("../../../../../public/assets/images/can_help_bg.png");
  width: 100%;
  background-size: 100% 100%;
}
.alcorn-home-we-can-help .justify-content-right {
  justify-content: right;
}
.alcorn-home-we-can-help .main_why_can_help h3 {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}
.alcorn-home-we-can-help .main_why_can_help p {
  color: #fff;
  font-size: 16px;
  text-align: left;
}
.alcorn-home-we-can-help .main_why_can_help a {
  display: inline-block;
  margin-top: 15px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: var(--main-color);
  text-decoration: none;
  border-radius: 5px;
  min-width: 265px;
  text-align: center;
  margin-left: 10px;
}
.alcorn-home-we-can-help .main_why_can_help a:hover {
  background-color: var(--color-3);
  color: #fff;
}
.alcorn-home-we-can-help .main_why_can_help a span {
  padding-right: 10px;
}

.alcorn-home-testimonial .main_Testimonials {
  padding: 40px 0;
  background: url("../../../../../public/assets/images/testimonial-img.png");
  width: 100%;
  background-size: 100% 100%;
}
.alcorn-home-testimonial .main_why_testimonial {
  text-align: center;
}
.alcorn-home-testimonial .main_why_testimonial h2 {
  font-size: 33px;
  color: var(--color-3);
  font-weight: 800;
}
.alcorn-home-testimonial .main_why_testimonial h2 span {
  color: rgb(3, 100, 5);
}
.alcorn-home-testimonial .main_why_testimonial h4 {
  font-size: 22px;
  color: rgb(3, 100, 5);
  font-weight: 800;
  padding-bottom: 5px;
}
.alcorn-home-testimonial .card-testimonial {
  background: url("../../../../../public/assets/images/testimonial.png");
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 10px;
  position: relative;
  background-size: 100% 100%;
  margin-bottom: 50px;
  margin-left: 10px;
}
.alcorn-home-testimonial .card-testimonial h2 {
  word-break: break-all;
  margin: 0;
  font-size: 1.5em;
  color: #000;
  font-weight: bold;
}
.alcorn-home-testimonial .card-testimonial h3 {
  margin: 0;
  font-size: 1em;
  color: #666;
  margin-bottom: 15px;
}
.alcorn-home-testimonial .card-testimonial p {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 15px;
  max-height: 100px;
  min-height: 100px;
  overflow-y: auto;
}
.alcorn-home-testimonial .stars-testimonial {
  color: rgb(0, 128, 0);
  margin-bottom: 15px;
  font-size: 19px;
}
.alcorn-home-testimonial .profile-pic-testimonial {
  border-radius: 50%;
  border: 3px solid white;
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: -35px;
  right: 0;
}
.alcorn-home-testimonial .profile-pic-testimonial img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 100%;
}
.alcorn-home-testimonial .main-slider-testimonials .slick-arrow {
  display: none !important;
}

.alcorn-home-help-and-support .main_Support {
  padding: 40px 0;
  background: linear-gradient(90deg, #c4f5ff 0%, #baffae 100%);
}
.alcorn-home-help-and-support .main_why_choose_us h4 {
  font-size: 22px;
  color: rgb(3, 100, 5);
  font-weight: 800;
  padding-bottom: 5px;
}
.alcorn-home-help-and-support .design_ul_list ul {
  padding: 20px 0 0;
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.alcorn-home-help-and-support .design_ul_list ul li a {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-3);
}
.alcorn-home-help-and-support .design_ul_list ul li a span {
  padding-right: 10px;
}
.alcorn-home-help-and-support .card-support {
  margin-right: 10px;
  margin-top: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  position: relative;
  height: 220px;
}
.alcorn-home-help-and-support .icon-support {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.alcorn-home-help-and-support .icon-support img {
  width: 50px !important;
  height: 50px;
  padding: 5px;
}
.alcorn-home-help-and-support .card-support h2 {
  margin-top: 30px;
  font-size: 20px;
  color: #000;
  font-weight: bold;
}
.alcorn-home-help-and-support .card-support p {
  font-size: 0.9em;
  color: rgb(0, 81, 89);
  margin-bottom: 15px;
}
.alcorn-home-help-and-support .card-support a {
  display: inline-block;
  font-size: 1em;
  color: rgb(0, 80, 89);
  text-decoration: none;
}

.alcorn-home-contactus {
  /* ============modul============ */
}
.alcorn-home-contactus .padding-b-t-20 {
  padding: 20px 0;
}
.alcorn-home-contactus .contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  box-shadow: -10px 12px 60px 14px rgb(156, 191, 232);
}
.alcorn-home-contactus .input_detail_sectrion {
  display: flex;
  flex-direction: column;
}
.alcorn-home-contactus .input_detail_sectrion input {
  margin-bottom: 20px;
  padding: 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 3px 8px 1px rgba(193, 193, 193, 0.5803921569);
  height: 55px;
  font-size: 18px;
  font-weight: bold;
}
.alcorn-home-contactus .input_detail_sectrion textarea {
  margin-bottom: 20px;
  padding: 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 3px 8px 1px rgba(193, 193, 193, 0.5803921569);
  height: 100px;
  font-size: 16px;
  font-weight: bold;
}
.alcorn-home-contactus .input_detail_sectrion button {
  padding: 10px 20px;
  background-color: rgb(0, 82, 180);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
}
.alcorn-home-contactus .input_detail_sectrion button span {
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  color: rgb(0, 82, 180);
  background: #fff;
}
.alcorn-home-contactus .detail_text_main p {
  font-size: 14px;
  color: rgb(0, 48, 144);
}
.alcorn-home-contactus .detail_text_main h2 {
  font-size: 35px;
  color: rgb(22, 51, 2);
  font-weight: 700;
}
.alcorn-home-contactus .info-con {
  text-align: left;
}
.alcorn-home-contactus .info-item-con {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.alcorn-home-contactus .icon-con {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-size: cover;
}
.alcorn-home-contactus .phone-icon-con i {
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #ccc;
  color: rgb(0, 82, 180);
}
.alcorn-home-contactus .location-icon-con i {
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #ccc;
  color: rgb(0, 82, 180);
  height: 45px;
  width: 45px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alcorn-home-contactus .main_profile_con {
  display: flex;
}
.alcorn-home-contactus .main_profile_con img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.alcorn-home-contactus .location-icon-con {
  margin-right: 10px;
}
.alcorn-home-contactus .teax_name_con h2 {
  font-size: 20px;
  font-weight: bold;
  color: #00318f;
}
.alcorn-home-contactus .teax_name_con .email-con {
  font-size: 16px;
  font-weight: bold;
  color: rgb(3, 100, 5);
}
.alcorn-home-contactus .info-item-con a {
  display: flex;
  align-items: center;
}
.alcorn-home-contactus .info-item-con a {
  color: rgb(0, 48, 144);
  font-size: 16px;
}
.alcorn-home-contactus .input_detail_sectrion button:hover {
  background-color: var(--main-color);
  color: #000;
}
.alcorn-home-contactus .Registra_modul_Request {
  max-width: 800px;
}
.alcorn-home-contactus .Registra_modul_Request .modal-content {
  border-radius: 0;
  padding: 20px;
}
.alcorn-home-contactus .back_color_main {
  text-align: center;
}
.alcorn-home-contactus .thank_heading_detail {
  text-align: center;
}
.alcorn-home-contactus .thank_heading_detail h4 {
  font-size: 25px;
  color: var(--color-3);
  font-weight: 800;
}
.alcorn-home-contactus .thank_heading_detail p {
  font-size: 20px;
  color: #000;
  margin-bottom: 0;
}
.alcorn-home-contactus .back_color_main {
  padding: 8px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-3);
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
  border: 0;
  max-width: 225px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.alcorn-home-contactus .request_send {
  padding-top: 30px;
}
.alcorn-home-contactus .back_color_main:hover {
  color: #fff;
  background-color: rgb(26, 147, 28);
}
.alcorn-home-contactus .back_color_main span i {
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(26, 147, 28);
}

.bg_blog_detail_home {
  background: url("../../../../../public/assets/images/blog_bg.png");
  width: 100%;
  background-size: 100% 100%;
  height: 200px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.bg_blog_detail_home h2 {
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.bg_blog_detail_home h4 {
  margin: 5px 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.bg_blog_detail_home p {
  margin: 5px 0;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.main_detail_Knowledge ul {
  border-bottom: 0 !important;
  justify-content: center;
}
.main_detail_Knowledge ul li button {
  border: 0 solid transparent !important;
  border-radius: 0;
  background: transparent !important;
  font-size: 22px;
  color: #000;
  font-weight: 600;
  padding: 0;
  padding-right: 10px;
}
.main_detail_Knowledge ul li button.active {
  border: 0px solid #7290b9 !important;
  border-radius: 0;
  background: transparent !important;
  font-size: 22px;
  color: rgb(3, 100, 5) !important;
  font-weight: 600;
}

.card-content-knowledge {
  text-align: center;
}

.between_detail_sec {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}

.stats-section {
  background-color: var(---color-4);
  padding: 20px 0;
  color: white;
  text-align: center;
}

.stat-item {
  display: flex;
}

.stat-icon {
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 10px;
}
.stat-icon img {
  max-width: 40px;
}

.main_counter {
  text-align: left;
}
.main_counter h4 {
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.main_counter p {
  padding: 0;
  margin: 0;
}

.youtube-video {
  cursor: pointer;
  height: 250px;
  width: 100%;
  position: relative;
}
.youtube-video .youTubeIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 63px;
  height: 63px;
  transform: translate(-50%, -50%);
}
.youtube-video img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.data-scroll {
  max-height: 75px;
  overflow-y: auto;
}

.service-logo-svg {
  position: relative;
  z-index: 9;
  height: 80px;
  width: 80px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.service-logo-svg img {
  padding: 3px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}

.text-right p {
  text-align: right;
}/*# sourceMappingURL=Home.css.map */