.Odm-query{
    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }
    .main_header_sosal.header_padd_20 {
        display: none;
    }
    .banner_detail_text p {
        font-size: 18px;
        color: rgb(0 49 143);
        margin-bottom: 0;
    }
    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 100%);
        padding: 10px 0;
    }
    .banner_detail_text h2 {
        font-size: 45px;
        color: var(--color-3);
        font-weight: 800;
    }
    .banner_detail_text h2 span {
        color: rgba(3, 100, 5, 1);
    }
    .slider-items-SPGS {
        background: url('../../../../../public/assets/images/download/banner_SPGS.png');
        width: 100%;
        background-size: cover !important;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat !important;

        position: relative;
    }
    .input_main_query {
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        box-shadow: 0px 10px 36px 7px rgb(156 191 232);
    }
    .main_quary_input {
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 4px;
        width: 100%;
        box-shadow: none;
        height: 52px;
        font-size: 18px;
        border: 1px solid #ebebeb;
        font-weight: bold;
    }
    .main_quary_input_textarea {
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 4px;
        width: 100%;
        box-shadow: none;
        height: 70px;
        font-size: 18px;
        border: 1px solid #ebebeb;
        font-weight: bold;
    }
    .input_detail_query {
        h3 {
            font-size: 32px;
            color: var(--color-3);
            font-weight: 800;
            span {
                color: var(--main-color);
            }
        }
    }
    .submil_button {
        background: var(--color-3);
        padding: 12px 30px;
        border-radius: 0;
        font-size: 15px;
        font-weight: 600;
        font-family: 'geometr415-blk-bt', sans-serif;
        color: #fff;
        width: 100%;
        border: 0;
        display: block;
        &:hover {
            background: var(--main-color);
            color: #000;
        }
    }
    .mt-100 {
        margin-top: 100px;
    }
    .mb-100 {
        margin-bottom: 105px;
    }
    
}