.faq-section .main_header_sosal.header_padd_20 {
  display: none;
}
.faq-section .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.faq-section .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.faq-section .faq_bg_detail {
  background: rgb(243, 245, 246);
}
.faq-section .main_faq_asked {
  max-width: 1000px;
  margin: 0 auto;
}
.faq-section .main_faq_asked .accordion-item {
  margin-bottom: 10px;
}
.faq-section .main_faq_asked .accordion-collapse {
  padding: 10px;
}
.faq-section .asked_question h4 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
  text-align: center;
}
.faq-section .accordion-button {
  color: #000;
  background-color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
}
.faq-section .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff;
  font-size: 16px;
}
.faq-section .accordion-button:focus {
  box-shadow: none;
}
.faq-section .accordion-button::after {
  filter: blur(0.5px);
}
.faq-section .accordion-item {
  border: none;
}
.faq-section .faq-answer {
  text-align: justify;
}
.faq-section .terms-privacy-div {
  text-align: justify;
}/*# sourceMappingURL=Faq.css.map */