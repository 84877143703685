.no-padding {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "geometr415-blk-bt", sans-serif;
}

:root {
  --main-color: rgba(50, 251, 53, 1);
  --secondary-color: rgba(50, 251, 53, 1);
  --color-3: rgba(0, 48, 144, 1);
  ---color-4: rgba(22, 51, 0, 1);
}

a {
  text-decoration: none;
}

.main_solar_about {
  background: #fff;
  box-shadow: -1px 2px 4.5px 0px #cdcdcd;
  border-radius: 10px;
  padding: 0 25px 20px;
  border: 1px solid #bcbcbc;
  overflow: hidden;
  text-align: center;
  margin-bottom: 25px;
}

.solar_about_text h3 {
  font-size: 23px;
  color: var(---color-4);
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}

.solar_about_img img {
  width: 100%;
  border-radius: 0 0 150px 150px;
  max-height: 280px;
  min-height: 280px;
  -o-object-fit: cover;
     object-fit: cover;
}

.solar_about_text p {
  padding-right: 6px;
  padding-left: 6px;
  font-size: 16px;
  color: #000;
  text-align: center;
}

.profile_about_main {
  text-align: center;
}

.profile_about_img {
  padding: 20px;
  height: 200px;
  border: 1px solid rgb(241, 177, 13);
  width: 100%;
  background-color: #fff;
  max-width: 200px;
  margin: 0 auto;
}
.profile_about_img img {
  width: 100%;
  background: #ccc;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.profile_about_main h4 {
  padding: 10px 0;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
}

.profile_about_main p {
  font-size: 14px;
  max-height: 200px;
  overflow-y: auto;
}

.justify-content-center {
  justify-content: center;
}

.padding-b-t-50 {
  padding: 50px 0;
}

.bg_images_product {
  background: url("../../../../../public/assets/images/Products/Div-section-2.png");
  background-size: 100% 100%;
}

.bg_images_product_next {
  background: url("../../../../../public/assets/images/Products/Div-section-1.png");
  background-size: 100% 100%;
}

.slider-items-products {
  background: url("../../../../../public/assets/images/Products/banner_img.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 500px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}

.product_text_detail p {
  text-align: justify;
}

.product_text_detail a {
  border-radius: 10px;
  background-color: var(---color-4);
}

.product_text_detail a:hover {
  background-color: var(--main-color);
  color: #000;
}

.dot-pattern {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 200px;
  height: 200px;
  background-image: url("../../../../../public/assets/images/fillers.png");
  opacity: 0.5;
}

.adout_mainsection {
  position: relative;
}

.max-content {
  padding-left: 6px;
  padding-right: 6px;
  max-height: 300px;
  overflow-y: auto;
}

.solar_about_text .max-content {
  max-height: 120px;
  scrollbar-width: none;
}
.solar_about_text .max-content::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}

li.who-we-are-list p.who-we-are-list-points {
  text-align: unset;
  font-size: 16px;
}

.main-image {
  width: 100%;
  max-width: 400px;
  max-height: 400px;
  height: auto;
  border-radius: 10px;
  -o-object-fit: contain;
     object-fit: contain;
}

.inset-image {
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  bottom: -20px;
  right: 20px;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.about_section_detail h6 {
  color: var(--color-3);
  font-size: 30px;
  font-family: "geometr415-blk-bt", sans-serif;
  font-weight: 600;
}

.about_section_detail h2 {
  color: var(---color-4);
  font-size: 34px;
  font-weight: 800;
  font-family: "geometr415-blk-bt", sans-serif;
}

.about_section_detail {
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.29);
  background: #fff;
}

.about_section_detail ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.about_section_detail ul li {
  width: 50%;
  padding-bottom: 10px;
}

.main_chek_icon img {
  width: 20px;
  margin-right: 10px;
}

.about-us-page .main_header_sosal.header_padd_20 {
  display: none;
}
.about-us-page .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.about-us-page .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}

@media (min-width: 768px) {
  .main_solar_about {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .main_solar_about {
    height: auto;
  }
}/*# sourceMappingURL=About.css.map */