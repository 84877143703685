/* ==============sosal icon============== */
/* .body:has(.show) {
   overflow-y: hidden;
  } */
  .menu-open {
    overflow: hidden !important;
   
}
.menu-open::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: #0000003f;
    z-index: 1;
    
}
input{
font-weight: 400 !important
;
}
.sosal_email_icon a{
    color: #fff;
    font-size: 16px;
    padding-right: 10px;
}
.sosal_mid_icon ul{
padding: 0;
margin: 0;
list-style: none;
}
.sosal_mid_icon ul li{
   display: inline-block;
   padding: 0 5px;
    }
    .sosal_mid_icon ul li a i{
        color: #fff;
        font-size: 22px;
         }
         .sosal_mid_icon{
            text-align: right;
         }
/* ==============sosal icon============== */
.padding-b-t-50 {
    padding: 50px 0;
}
.main_why_choose_us h2 {
    font-size: 32px;
    color: var(--color-3);
    font-weight: 800;
}
.main_detail_services a {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: rgba(26, 147, 28, 1);
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
}
.srvices_images img {
    width: 100%;
    max-height: 400px;
    height: 100%;
    max-width: 400px;
}
.padding-b-t-20 {
    padding: 20px 0;
}
#canv{
    border: 1px solid #c9c9c9;
}

.custom-captcha div{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
   
}
.error{
    font-size: 12px;
    color: red;
    margin: 5px 0;
}
.custom-line-clamp{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4; /* Number of lines to show */
    line-height: 1.2; /* Adjust line height as needed */
    max-height: calc(1.2em * 4); /* Should match the line-height * number of lines */
    white-space: normal;
}

/*contact-us*/

.contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 30px;
}

.input_detail_sectrion {
    display: flex;
    flex-direction: column;
}

.input_detail_sectrion input {
    /* margin-bottom: 28px; */
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 5px 3px -1px #50505094;
    height: 52px;
    font-size: 16px;
    border: 1px solid #ebebeb;
    /* font-weight: 600; */
    font-family: "Roboto", sans-serif;
}
.input_detail_sectrion input::placeholder{
color: rgba(115, 123, 143, 1);
}
.main_input_detail input{
    font-family: "Roboto", sans-serif;
}
.main_input_detail input::placeholder{
    color: rgba(115, 123, 143, 1);
    }
.input_detail_sectrion textarea {
    /* margin-bottom: 28px; */
    padding: 10px;
    border: 0;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 5px 3px -1px #50505094;
    border: 1px solid #ebebeb;
    height: 100px;
   font-size: 16px;
    /* font-weight: 600; */
    font-family: "Roboto", sans-serif;
}
.bg_detail_drop li a{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}
.dropend a{
    font-family: "Roboto", sans-serif;
    font-weight: 500 !important;
}
.input_detail_sectrion textarea::placeholder{
    color: rgba(115, 123, 143, 1);
    }
.input_detail_sectrion button {
    padding: 10px 20px;
    background-color: rgba(0, 82, 180, 1);
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    text-transform: uppercase;
}
.input_detail_sectrion button span{
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px;
    color: rgba(0, 82, 180, 1);
    background: #fff;
}
.detail_text_main p{
    font-size: 14px;
    color: rgba(0, 48, 144, 1) ;
   
}
.detail_text_main h2{
    font-size: 35px;
    color: rgba(22, 51, 2, 1);
    font-weight: 700;
} 
.info-con {
    text-align: left;
}

.info-item-con {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.icon-con {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-size: cover;
}

.phone-icon-con i{
    background:#fff;
    padding: 10px;
    border-radius: 100%;
    border: 1px solid #ccc;
    color: rgba(0, 82, 180, 1);
}

.location-icon-con i{
    background:#fff;
    padding: 10px;
    border-radius: 100%;
    border: 1px solid #ccc;
    color: rgba(0, 82, 180, 1);
    height: 45px;
    width: 45px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_profile_con{
    display: flex;
}
.main_profile_con img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
   
}
.location-icon-con{
    margin-right: 10px;
}
.teax_name_con h2{
    font-size: 20px;
    font-weight: bold;
    color: #00318f;
}
.teax_name_con .email-con{
    font-size: 16px;
    font-weight: bold;
    color: rgba(3, 100, 5, 1);
}
.info-item-con a{
    display: flex;
    align-items: center;
}
.info-item-con a{
    color:rgba(0, 48, 144, 1);
    font-size: 16px;
}
.input_detail_sectrion button:hover{
    background-color: var(--main-color);
    color: #000;
}
.enquiry-title{
    font-size: 32px;
    color: var(--color-3);
    font-weight: 800;
}
.mages_detail_section .input-group{
    width: 100% !important;
}
::-webkit-scrollbar {
    width: 3px; 
    height: 5px; 
    /* background: #000;  */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
    background: #6f6f6f;
    border-radius: 10px;
}

/* Optional: Scrollbar track */
::-webkit-scrollbar-track {
    /* background: #000;  */
}
.no-data{
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pull-left{
    display: flex;
    justify-content: start;
    align-items: start;
    gap
    :9px
}