.alcorn-contact-us {
    .padding-b-t-50 {
        padding: 50px 0;
    }

    .contact-form {
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        box-shadow: -10px 12px 60px 14px rgb(156 191 232);
    }
    .input_detail_sectrion {
        display: flex;
        flex-direction: column;
    }

    .input_detail_sectrion input {
        // margin-bottom: 20px;
        padding: 10px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        box-shadow: 0px 3px 8px 1px #c1c1c194;
        height: 55px;
        font-size: 18px;
        // font-weight: bold;
    }

    .input_detail_sectrion textarea {
        margin-bottom: 20px;
        padding: 10px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        box-shadow: 0px 3px 8px 1px #c1c1c194;
        height: 100px;
        font-size: 16px;
        font-weight: bold;
    }

    .input_detail_sectrion button {
        padding: 10px 20px;
        background-color: rgba(0, 82, 180, 1);
        color: white;
        border: none;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        cursor: pointer;
        text-transform: uppercase;
    }

    .input_detail_sectrion button span {
        padding: 5px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        color: rgba(0, 82, 180, 1);
        background: #fff;
    }

    .detail_text_main h2 {
        font-size: 35px;
        color: rgba(22, 51, 2, 1);
        font-weight: 700;
    }

    .detail_text_main p {
        font-size: 14px;
        color: rgba(0, 48, 144, 1);
    }

    .main_profile_con {
        display: flex;
    }

    .main_profile_con img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .teax_name_con h2 {
        font-size: 20px;
        font-weight: bold;
        color: #00318f;
    }

    .teax_name_con .email-con {
        font-size: 16px;
        font-weight: bold;
        color: rgba(3, 100, 5, 1);
    }

    .info-item-con {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    .info-item-con a {
        color: rgba(0, 48, 144, 1);
        font-size: 16px;
    }
    .info-item-con a {
        display: flex;
        align-items: center;
    }
}
.contact-us-page{
    .location-icon-con i{
        margin-right: 10px;
        background:#fff;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: rgba(0, 82, 180, 1);
        height: 45px;
        width: 45px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }
}