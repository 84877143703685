.design-solar-product {
    .form-check-input-new:checked {
        background-color: rgba(0, 48, 144, 1);
        border-color: rgba(0, 48, 144, 1);
    }

    .form-check-input-new {
        width: 34px !important;
        height: 34px !important;
    }

    .main_extended_option {
        display: flex;
        align-items: center;
        position: relative;
    }

    .form-check-input-new:checked::before {
        content: "\2713";
        display: block;
        color: white;
        font-weight: bold;
        text-align: center;
        margin-top: 8px;
        font-size: 14px;
    }

    .form-check-new .form-check-label-new {
        color: rgba(33, 33, 33, 1);
        padding-left: 10px;
    }

    .form-check-new {
        display: inline-flex;
        align-items: center;
        margin-right: 1em;
    }

    .custom-date-picker {
        position: relative;

        svg {
            position: absolute;
            top: 7px;
            right: 10px;
            color: #7b7b7b;
        }

        width: 100%;

        .react-datepicker-wrapper {
            width: 100%;

            input {
                width: 100%;
                border: 1px solid #ced4da !important;
                padding: .375rem .75rem;
                border-radius: .375rem;
            }
        }
    }

    .card-fans-details {
        background: #fff;

        .customize-header {
            color: #fff;
            background: var(--color-3);
            font-size: 18px;
            font-weight: 900;
            padding: 10px;
            border-radius: 0;
        }
    }

    .main_input_detail label {
        color: var(--color-3);
        font-size: 15px;
        padding-bottom: 5px;
        font-weight: 900;
    }

    .bg_images_se {
        background: url('../../../../../public/assets/images/srvices/Background-Image.png');
        background-size: 100% 100%;
    }

    .main_detail_persanal {
        padding: 20px;
    }

    .main_input_detail {
        .sectopn_class {
            height: 45px;
            border-radius: 6px;
            font-size: 15px;
            border: 1px solid #ccc;
            width: 100%;
        }
    }

    .options {
        display: flex;
        align-items: center;

        input[type="radio"] {
            display: none;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            font-size: 16px;

            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #ccc;
                margin-right: 5px;
            }
        }

        input[type="radio"]:checked {
            &+label {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: green;
                    margin-right: 5px;
                }
            }
        }
    }

    .form-control-technology {
        padding: 10px;
        border: 1px solid #c1c1c194;
        border-radius: 4px;
        width: 100%;
        height: 100px;
        font-size: 14px;
    }

    .main_input_detail_button {
        background: var(--color-3);
        padding: 10px 30px;
        border-radius: 16px;
        font-size: 18px;
        border: 0;
        font-weight: 600;
        font-family: 'geometr415-blk-bt', sans-serif;
        color: #fff;

        &:hover {
            background: var(--main-color);
            color: #fff;
        }
    }

    .slider-items-SPGS {
        background: url('../../../../../public/assets/images/download/banner_SPGS.png');
        width: 100%;
        background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
    }

    .file-input {
        padding-bottom: 10px;
        position: relative;
    }

    .folder_main_bro {
        height: 45px;
        width: 100%;
        border-radius: 5px;
        background: #ffffff;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid rgba(224, 224, 224, 1);
        color: #919191;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .file-input>[type='file'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
    }

    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }

    .slider-items-srvices {
        background: url('../../../../../public/assets/images/srvices/srvices.png');
        width: 100%;
        min-height: 500px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        position: relative;
    }

    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 100%);
        padding: 10px 0;
    }

    .main_why_choose_us h2 {
        font-size: 32px;
        color: var(--color-3);
        font-weight: 800;
    }

    .banner_detail_text p {
        font-size: 18px;
        color: rgb(0 49 143);
        margin-bottom: 0;
    }

    .banner_detail_text h2 {
        font-size: 45px;
        color: var(--color-3);
        font-weight: 800;
    }

    .banner_detail_text h2 span {
        color: rgba(3, 100, 5, 1);
    }
.custom-mobile-code{
    .iti--allow-dropdown{
        width: 100%;
    }
}
}
.multiple-img-box{
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #000;
    border-radius: 6px;
    padding: 6px;
    .absolute-img{
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 20px;
        cursor: pointer;
        color: #fff;

    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.image-preview-container{
    flex-wrap: wrap;
}