.alcorn-epc-services {
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }

    .slider-items-caPex {
        background: url("../../../../../public/assets/images/CapEx/Capex-Banner.png");
        width: 100%;
        background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;

        .banner_detail_text {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translate(0, -50%);
            background: linear-gradient(90deg, #fff 0%, transparent 100%);
            padding: 10px 0;

            h2 {
                font-size: 45px;
                color: var(--color-3);
                font-weight: 800;

                span {
                    color: rgba(3, 100, 5, 1);
                }
            }
        }


    }

    .padding-b-t-50 {
        padding: 50px 0;
    }

    .padding-b-t-20 {
        padding: 20px 0;
    }

    .bg_images_inverters_series {
        background: url("../../../../../public/assets/images/inverters_series/Background-Image.png");
        background-size: 100% 100%;

        .main_detail_services {
            h2 {
                font-size: 35px;
                color: var(--color-3);
                font-weight: bold;

                span {
                    color: rgba(3, 100, 5, 1);
                }
            }
        }

        .s-right {
            text-align: right;
        }

        .b-right {
            img {
                border-radius: 0 75px 0 0;
            }
        }

        .srvices_images {
            img {
                width: 100%;
                max-height: 400px;
                height: 100%;
                max-width: 400px;
            }
        }

        .list_style_auto {
            ul {
                list-style: auto;
            }
        }

        .main_detail_services {
            ul {
                padding: 20px 10px 0 18px;

                li {
                    padding-bottom: 18px;
                    font-size: 16px;
                }
            }
        }
    }

  

    // .contact-form {
    //     background-color: #fff;
    //     padding: 20px;
    //     border-radius: 30px;
    //     box-shadow: -10px 12px 60px 14px rgb(156 191 232);

    //     .main_detail_services {
    //         h2 {
    //             font-size: 35px;
    //             color: var(--color-3);
    //             font-weight: bold;

    //             span {
    //                 color: rgba(3, 100, 5, 1);
    //             }
    //         }

    //         .pb-4 {
    //             padding-bottom: 1.5rem !important;
    //         }
    //     }

    //     .input_detail_sectrion {
    //         display: flex;
    //         flex-direction: column;

    //         input {
    //             margin-bottom: 28px;
    //             padding: 10px;
    //             border-radius: 4px;
    //             width: 100%;
    //             box-shadow: 0px 5px 2px 0px #c1c1c194;
    //             height: 52px;
    //             font-size: 18px;
    //             border: 1px solid #ebebeb;
    //             font-weight: bold;
    //         }

    //         textarea {
    //             margin-bottom: 28px;
    //             padding: 10px;
    //             border: 0;
    //             border-radius: 4px;
    //             width: 100%;
    //             box-shadow: 0px 3px 2px 3px #c1c1c194;
    //             height: 100px;
    //             font-size: 16px;
    //             font-weight: bold;
    //         }

    //         button {
    //             padding: 10px 20px;
    //             background-color: rgba(0, 82, 180, 1);
    //             color: white;
    //             border: none;
    //             font-size: 16px;
    //             font-weight: bold;
    //             border-radius: 30px;
    //             cursor: pointer;
    //             text-transform: uppercase;

    //             &:hover {
    //                 background-color: var(--main-color);
    //                 color: #000;
    //             }

    //             span {
    //                 padding: 5px;
    //                 font-size: 16px;
    //                 font-weight: bold;
    //                 border-radius: 30px;
    //                 color: rgba(0, 82, 180, 1);
    //                 background: #fff;
    //             }
    //         }
    //     }
    // }

    // .Registra_modul_Request {
    //     max-width: 800px;

    //     .modal-content {
    //         border-radius: 0;
    //         padding: 20px;

    //         .thank_heading_detail {
    //             text-align: center;

    //             h4 {
    //                 font-size: 25px;
    //                 color: var(--color-3);
    //                 font-weight: 800;
    //             }

    //             p {
    //                 font-size: 20px;
    //                 color: #000;
    //                 margin-bottom: 0;
    //             }

    //             .request_send {
    //                 padding-top: 30px;

    //                 .back_color_main {
    //                     padding: 8px 25px;
    //                     font-size: 20px;
    //                     font-weight: bold;
    //                     color: #fff;
    //                     background-color: var(--color-3);
    //                     text-decoration: none;
    //                     border-radius: 50px;
    //                     text-align: center;
    //                     border: 0;
    //                     max-width: 225px;
    //                     width: 100%;
    //                     display: flex;
    //                     align-items: center;
    //                     justify-content: space-between;
    //                     margin: 0 auto;

    //                     &:hover {
    //                         color: #fff;
    //                         background-color: rgba(26, 147, 28, 1);
    //                     }

    //                     span {
    //                         i {
    //                             background-color: #fff;
    //                             border-radius: 100%;
    //                             padding: 10px;
    //                             font-size: 16px;
    //                             font-weight: bold;
    //                             color: rgba(26, 147, 28, 1);
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

}