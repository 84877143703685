.business-division .main_header_sosal.header_padd_20 {
  display: none;
}
.business-division .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.business-division .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.business-division .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.business-division .banner_detail_text h2 {
  font-size: 45px;
  color: var(--color-3);
  font-weight: 800;
}
.business-division .slider-items-Bussiness {
  background: url("../../../../../public/assets/images/CapEx/Bussiness-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.business-division .slider-items-caPex {
  background: url("../../../../../public/assets/images/CapEx/Capex-Banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.business-division .bg_images_sales {
  background: url("../../../../../public/assets/images/Products/Div-section-3.png");
  background-size: 100% 100%;
}
.business-division .main_view_detail {
  border: 0;
}
.business-division .main_view_detail img {
  width: 100%;
  border-radius: 10px;
  max-height: 270px;
  background: #fff;
}
.business-division .all_Information_main {
  border-bottom: 0 !important;
  border-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
}
.business-division .all_Information_main button {
  width: 30%;
  border: 2px solid #ccc !important;
  padding: 6px 0 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.business-division .all_Information_main button img {
  width: 100%;
  max-width: 110px;
  height: 70px;
  margin: 0 auto;
}
.business-division .all_Information_main button.active {
  border: 2px solid var(--color-3) !important;
  padding: 6px 0 10px;
  border-radius: 5px;
  margin-top: 20px;
}
.business-division .battery-product-detail-text h4 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
}
.business-division .battery-product-detail-text p {
  text-align: justify;
}
.business-division .enquiry_button {
  padding: 12px 32px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-3);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
}
.business-division .enquiry_button:hover {
  background-color: var(--main-color);
}
.business-division .store_button {
  padding: 12px 32px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(26, 147, 28);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
}
.business-division .store_button:hover {
  background-color: var(--main-color);
}
.business-division .download_now_button a {
  padding: 12px 32px;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-3);
  background-color: #fff;
  border: 3px solid var(--color-3);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
  margin-left: 5vw;
}
.business-division .download_now_button a:hover {
  color: #fff;
  background-color: var(--color-3);
  border: 3px solid var(--color-3);
}
.business-division .main_bg_detail_inve {
  background: #f0f0f0;
  padding: 3px 0;
}
.business-division .main_detail_inveter ul {
  border-bottom: 0 !important;
}
.business-division .main_detail_inveter ul li button {
  border: 2px solid transparent !important;
  border-radius: 0;
  background: transparent !important;
  color: #7290b9;
  font-weight: bold;
}
.business-division .main_detail_inveter ul li button.active {
  border: 2px solid #7290b9 !important;
  border-radius: 0;
  background: transparent !important;
  color: #7290b9 !important;
  font-weight: bold;
}
.business-division .detail_text_inveter {
  padding: 30px 0;
}
.business-division .detail_text_inveter ul li {
  font-size: 16px;
  color: #000;
}
.business-division .main_border_feedback {
  border-top: 1px solid #000;
}
.business-division .over_sales_main {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
}
.business-division .main_detail_sales h3 {
  padding: 5px 0;
  font-size: 24px;
  color: var(--color-3);
  font-weight: bold;
  margin-bottom: 0;
}
.business-division .main_drop_new_week select {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.43;
  background-color: #e6e6e6;
  color: #000;
  border: 1px solid #e6e6e6 !important;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}
.business-division .user-name {
  color: #818181;
  font-size: 20px;
}
.business-division .main_flex_div {
  display: flex;
  align-items: center;
}
.business-division .stars i {
  font-size: 18px;
  color: rgb(255, 137, 1) !important;
  padding-right: 4px;
}
.business-division .text_detail_best {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
.business-division .review-text {
  font-size: 18px;
  color: #9f9f9f;
  margin: 5px 0;
}
.business-division .verified-seller {
  font-size: 16px;
}
.business-division .rounded-circle {
  width: 50px;
  height: 50px;
}
.business-division .align-items-center {
  align-items: center;
}
.business-division .main_textarea_feed {
  padding: 0 40px;
}
.business-division .main_detail_textarea textarea {
  max-height: 200px;
  border-radius: 20px;
  padding: 20px;
  font-size: 20px;
  border: 2px solid #ccc;
  min-height: 200px;
  margin-bottom: 20px;
}
.business-division .main_detail_textarea a {
  background: var(--color-3);
  padding: 14px 40px;
  border-radius: 16px;
  font-size: 17px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
}
.business-division .list_style_auto ul {
  list-style: auto;
}

.bg_images_product .s-right {
  text-align: right;
}

.bg_images_product_next .s-left {
  text-align: left;
}/*# sourceMappingURL=BusinessDivision.css.map */