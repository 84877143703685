.blog-section{
    .main_header_sosal.header_padd_20 {
        display: none;
    }
    .slider-items-SPGS {
        background: url('../../../../../public/assets/images/download/banner_SPGS.png');
        width: 100%;
        // background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
        background-repeat: no-repeat !important;
        background-size: cover !important;
    }

    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 100%);
        padding: 10px 0;
    }

    .main_why_choose_us h2 {
        font-size: 32px;
        color: var(--color-3);
        font-weight: 800;
    }

    .banner_detail_text p {
        font-size: 18px;
        color: rgb(0 49 143);
        margin-bottom: 0;
    }

    .banner_detail_text h2 {
        font-size: 45px;
        color: var(--color-3);
        font-weight: 800;
    }

    .banner_detail_text h2 span {
        color: rgba(3, 100, 5, 1);
    }
    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }
}