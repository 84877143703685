@media screen and (min-width: 1200px) {
    .header_border_xs li{
        padding:0 10px;
      }
      
}
@media screen and (max-width: 1199px){
    .alcorn-home-we-can-help .main_why_can_help a{
        min-width: 320px !important;
    }
}
@media screen and (min-width: 992px) {
    .bg-primary-black {
        background: #fff;
        border-radius: 10px;
        padding: 5px 0;
    }  
    .dropend:hover > .dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
      }
      .bg_detail_drop{
        background: rgba(137, 234, 95, 1);
        border-radius: 12px;
        border: 0;
      }
      .m-right-10{
        margin-right: 5vw;
      }
      .border_lg_right{
        border-right: 1px solid #000;
    }
}
@media screen and (max-width: 991px) {
    .header-alcorn .dropend .dropdown-toggle{
        margin-left: 0px !important;
    }
    .dropdown-item.ps-2,.dropdown-item{
        padding-left: 0px !important;
    }
    .navbar-toggler {
        background-color: #00318f;
    }
    .header-alcorn .header_padd_20{
        display: none !important;
    }
    .banner_detail_text h2 {
        font-size: 30px !important;
    }
    .head_xs_i {
        transform: rotateZ(90deg);
    }
    .header_border_xs li a i{
       float: right;
    }

    .main_banner_headline h3{
        font-size: 40px;
    }
    .stat-item{
        margin-bottom: 15px;
      }
    .stat-item{
        margin-bottom: 15px;
      }
    .bg_detail_drop {
        background: transparent;
        border: 0;
    }
    .bg_detail_drop li a{
        color: #fff;
    }
    .por_detail_section{
        margin-bottom: 15px;
    }
    .bg_traprent_fix {
        background: #345ba6;
        position: sticky;
    }
    .main_nav_button a{
        background: transparent;
    }
    .bg-primary-black {
        background: transparent;
        padding: 0;
    }
    .main_nav_button a span{
        display: none;
    }
    .main_nav_button a i{
        display: block;        
        font-size: 30px;
    }
 
    .pas_detail_section {
        padding-top: 0;
    }
    .slider-items {
        max-height: 700px;
        height: 100%;
    }
    .navbar-nav .nav-link {
        color: #fff;
    }
   .bg_traprent_fix {
    background: #82b7e3 !important;
}
.bg_traprent_fix.sticky {
    background: #82b7e3 !important;
}
.main_footer_detail p{
    line-height: 24px;
    margin-bottom: 20px !important;
}
.header-alcorn .main_nav_button a{
    padding-left: 0px !important;
}
.header-alcorn .main_nav_button a:hover{
    background: none !important;
}
.alcorn-home-banner .pas_detail_section{
    padding-top: 80px !important;
}
.Odm-query .mt-100{
    margin-top: 0px !important;
    height: 96.5%;
}
}
@media screen and (min-width: 768px) {
   
    .main_why_choose_us h2 {
        font-size: 33px;
    }
      .dropend .dropdown-toggle {
        margin-left: 0.5em;
      }
}

@media screen and (max-width: 767px) {
    .all-services .left-section{
        flex-direction: column-reverse !important;
    }
    .capex-solar-project .main_detail_services h2{
        font-size: 24px;
    }
    .slick-prev, .slick-next{
        display: none !important;
    }
    .slider-items-products {
        min-height: 300px;
    }
    .slider-items-Solar{
        background-size: cover;
        min-height: 300px;
        max-height: 300px; 
    }
    .slider-items-Battery{
        background-size: cover;
        min-height: 300px;
        max-height: 300px;
    }
    .slider-items-Inverter{
        background-size: cover;
        min-height: 300px;
        max-height: 300px;
    }
    .slider-items-on-grid {
        background-size: cover;
        min-height: 300px;
        max-height: 300px;
    }
    .slider-items-caPex {
        min-height: 300px;
        max-height: 300px;
    }
    .slider-items-series {
        background-size: cover;
    }
    .main_solar_about{
        height: auto;
    }
    .design_ul_list ul {
      display: grid !important;
      gap: 10px;
    }
    .main_solar_about{
        margin-bottom: 15px;
    }
    .slider-items-srvices {
        min-height: 58vw;
    }
    .main_detail_services{
        margin-top: 20px;
    }
    .srvices_images{
        text-align: center !important;
    }
    .bg_images_on-grid  .flex_dir-xs,.business-division .flex_dir-xs{
            flex-direction: column-reverse;
    }
    .bg_images_product .flex_dir-xs{       
            flex-direction: column-reverse;    
    }
   
    .about_section_detail{
        margin-top: 40px;
      }
      .alcorn-home-product .p_text_detail p{
        text-align: left !important;
      }
      .inverter-list-section .inverters_series_bg{
        padding: 20px !important;
      }
     .contact-us-page .flex_dir-xs,.bg_images_product_next .flex_dir-xs{
        flex-direction: column-reverse !important;
     }
     .no-reverse .flex_dir-xs{
        flex-direction: column !important;
    }
      .bg_images_se .flex_dir-xs{
        flex-direction: column-reverse;
      }
      .alcorn-contact-us .detail_text_main h2, .alcorn-epc-services .bg_images_inverters_series .main_detail_services h2,  .solar-lighting-section .main_detail_services h2,.alcorn-opex-solar-project .bg_images_inverters_series .main_detail_services h2,.alcorn-revex-solar-project .bg_images_inverters_series .main_detail_services h2{
        font-size: 28px !important;
      }
      .store-locator .main_detail_section h3{
        font-size: 26px !important;
    }
    .main_solar_about{
        margin-bottom: 15px !important;
    }
    .mt-100 {
        margin-top: 0 !important;
    }
    .mb-100{
        margin-bottom: 0px !important;
    }
    .alcorn-home-we-can-help .main_can_help{
        background-size: auto !important;
    }
    .slider-items-on-grid,.inverter-list-section .slider-items-Inverter{
        background-size: cover !important;
        min-height: 300px !important;
        max-height: 300px !important;
    }
}

@media screen and (max-width: 576px) {
    .inverter-product-details .enquiry_button,.inverter-product-details .store_button{
        padding: 12px 20px !important;

    }
   
    .capex-model .thank_heading_detail p{
        font-size: 16px;
    }
    .capex-model .back_color_main{
        max-width: 190px;
        font-size: 16px
    }
    .capex-model .thank_heading_detail img{
width: 100%;
    }
    .pull-left label{
font-size: 14px ;
    }
    .main_why_choose_us p{
font-size: 14px !important;
    }
    .solar_about_text p{
        font-size: 14px !important;
        line-height: 24px !important;
    }
    .solar_about_text h3{
        font-size: 26px !important;
    }
    .Odm-query .input_detail_query h3 {
        font-size: 24px !important;
        font-weight: 800 !important;
    }
    .main_why_choose_us{
        padding-bottom: 20px !important;
    }
    .main_why_choose_us h2 {
        font-size: 25px !important;
    }
    .alcorn-home-we-can-help .main_why_can_help h3{
        font-size: 20px !important;
    }
    .store-locator .main_detail_section h3{
        font-size: 22px !important;
    }
    .store-locator .main_detail_locetion a{
        max-width:200px;
        text-align: center;
    }
    .locetion_button{
        display: flex;
        justify-content: start;
        flex-direction: column;
        gap: 10px;
    }
    .inverter-product-details .detail_text_inveter ul li,.inverter-product-details .battery-product-detail-text p{
        font-size: 14px !important;
        line-height: 22px;
    }
    .store-locator .main_store_locator a{
        font-size: 16px;
    }
    .store-locator .main_store_locator h2,.inverter-product-details .battery-product-detail-text h4{
        font-size: 24px !important;
    }
    .store-locator .main_detail_locetion h3,.inverter-product-details .main_detail_sales h3{
        font-size: 22px !important;
    }
    /* .inverter-product-details .enquiry_now_button{
        flex-direction: column;
    } */
    .inverter-product-details .download_now_button a{
        margin-left: 0 !important;
    }
    .alcorn-contact-form-services .contact-form .input_detail_sectrion button{
        font-size: 14px !important;
        padding: 8px 16px !important;
    }
    .solar-lighting-section .main_detail_services ul li,.alcorn-home-we-can-help .main_why_can_help p, .alcorn-opex-solar-project .bg_images_inverters_series .main_detail_services ul li,.alcorn-revex-solar-project .bg_images_inverters_series .main_detail_services ul li{
        font-size: 14px !important;
        line-height: 25px;
    }
    .main_detail_services p{
        font-size: 14px !important;
        line-height: 23px;
    }
    .download-section .catalogue_detail_img img{
        width: 50% !important;
    }
    .faq-section .asked_question h4{
        font-size: 24px !important;
    }
    .catalogue_detail_img {
        padding: 10px !important;
        height: auto !important;
    }
    .load-calculator .requirement_main h4 span{
        font-size: 17px !important;
        height: 22px !important;
        width: 22px !important;

    }
    .alcorn-contact-form-services .contact-form,.solar-lighting-section .contact-form{
        padding: 30px !important;
    }
    .load-calculator .requirement_main h4{
        font-size: 20px !important;
    }
    .alcorn-contact-form-services .contact-form .main_detail_services h2{
        font-size: 24px !important;
    }
    .alcorn-home-about .padding-b-t-50{
        padding: 90px 0px !important;
    }
    .padding-b-t-50{
        padding: 30px 0px !important;
    }
    .all-services .main_detail_services h2{
        font-size: 24px !important;
    }
    .main_why_choose_us h2 {
        font-size: 24px !important;
        font-weight: 700 !important;
    }
    .logo_footer img {
        width: 135px !important;
    }
    .product_text_detail p {
        font-size: 14px !important;
        line-height: 22px;
    }
    .inveter_detail_new h4{
        font-size: 24px !important;
    }
    .sosal_email_icon, .sosal_mid_icon{
        text-align: center;
    }
    .Odm-query .mt-100{
        margin-top: 0px !important;
    }
    .Odm-query .mb-100{
        margin-bottom: 0px !important;
    }
  .bg_best_product_1{
    margin-bottom: 15px;
  }
 
  .header_padd_20 {
    padding: 3px 0;
}
.sosal_email_icon{
    padding-bottom: 10px;
}
  .por_detail_section{
    margin-bottom: 15px;
  }
  
  .main_banner_headline h3 {
    color: #fff;
    font-size: 28px !important;
}

}
@media screen and (max-width: 480px) {
    .inverter-product-details .enquiry_now_button{
        gap: 0px !important;
    }
    .main_nav_services {
        padding: 12px 10px;
    }
    .main_nav_contact {
        padding: 12px 15px;
    }
    .enquiry_button {
        padding: 12px 10px;
        margin-right: 10px;
    }
    .store_button {
        padding: 12px;
    }
    .main_textarea_feed {
        padding: 0 10px;
    }
    .main_detail_inveter ul{
        flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    }
    .Registra_modul_Request .modal-content .thank_heading_detail img{
        width: 100% !important;
    }
}
@media screen and (max-width:400px) {
    
    .alcorn-home-we-can-help .main_why_can_help a{
        font-size: 14px !important;
        min-width:100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}