@import url('https://fonts.cdnfonts.com/css/geometr415-blk-bt');

body {
  color: black;
  /* font-family: 'Geometr415 Blk BT', sans-serif; */
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

.no-padding {
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Geometr415 Blk BT', sans-serif;
}

:root {
  --main-color: rgba(50, 251, 53, 1);
  --secondary-color: rgba(50, 251, 53, 1);
  --color-3: rgba(0, 48, 144, 1);
  ---color-4: rgba(22, 51, 0, 1);
}

a {
  text-decoration: none;
}