.alcorn-contact-us .padding-b-t-50 {
  padding: 50px 0;
}
.alcorn-contact-us .contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  box-shadow: -10px 12px 60px 14px rgb(156, 191, 232);
}
.alcorn-contact-us .input_detail_sectrion {
  display: flex;
  flex-direction: column;
}
.alcorn-contact-us .input_detail_sectrion input {
  padding: 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 3px 8px 1px rgba(193, 193, 193, 0.5803921569);
  height: 55px;
  font-size: 18px;
}
.alcorn-contact-us .input_detail_sectrion textarea {
  margin-bottom: 20px;
  padding: 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 3px 8px 1px rgba(193, 193, 193, 0.5803921569);
  height: 100px;
  font-size: 16px;
  font-weight: bold;
}
.alcorn-contact-us .input_detail_sectrion button {
  padding: 10px 20px;
  background-color: rgb(0, 82, 180);
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
}
.alcorn-contact-us .input_detail_sectrion button span {
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  color: rgb(0, 82, 180);
  background: #fff;
}
.alcorn-contact-us .detail_text_main h2 {
  font-size: 35px;
  color: rgb(22, 51, 2);
  font-weight: 700;
}
.alcorn-contact-us .detail_text_main p {
  font-size: 14px;
  color: rgb(0, 48, 144);
}
.alcorn-contact-us .main_profile_con {
  display: flex;
}
.alcorn-contact-us .main_profile_con img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.alcorn-contact-us .teax_name_con h2 {
  font-size: 20px;
  font-weight: bold;
  color: #00318f;
}
.alcorn-contact-us .teax_name_con .email-con {
  font-size: 16px;
  font-weight: bold;
  color: rgb(3, 100, 5);
}
.alcorn-contact-us .info-item-con {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.alcorn-contact-us .info-item-con a {
  color: rgb(0, 48, 144);
  font-size: 16px;
}
.alcorn-contact-us .info-item-con a {
  display: flex;
  align-items: center;
}

.contact-us-page .location-icon-con i {
  margin-right: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 100%;
  border: 1px solid #ccc;
  color: rgb(0, 82, 180);
  height: 45px;
  width: 45px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us-page .main_header_sosal.header_padd_20 {
  display: none;
}
.contact-us-page .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.contact-us-page .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}/*# sourceMappingURL=Contactus.css.map */