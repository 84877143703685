@media screen and (min-width: 768px) {
  .main_why_choose_us h2 {
    font-size: 33px;
  }
}
.on-grid {
  /* ================Products page==================== */
  /* ================Products psge==================== */
}
.on-grid .s-left {
  text-align: left;
}
.on-grid .s-right {
  text-align: right;
}
.on-grid .product_text_detail a {
  border-radius: 10px;
  background: rgb(22, 51, 0);
}
.on-grid .product_text_detail a:hover {
  background-color: rgb(26, 147, 28);
}
.on-grid .main_why_choose_us h2 span {
  color: rgb(3, 100, 5);
}
.on-grid .product_text_detail p {
  text-align: justify;
}
.on-grid .main_detail_services ul {
  padding: 20px 10px 0 18px;
}
.on-grid .main_detail_services ul li {
  padding-bottom: 18px;
  font-size: 16px;
}
.on-grid .main_header_sosal.header_padd_20 {
  display: none;
}
.on-grid .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.on-grid .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.on-grid .bg_images_product {
  background: url("../../../../../public/assets/images/Products/Div-section-2.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.on-grid .bg_images_product_next {
  background: url("../../../../../public/assets/images/Products/Div-section-1.png");
  background-size: 100% 100%;
}
.on-grid .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.on-grid .banner_detail_text h2 {
  line-height: 45px;
  font-size: 45px;
  color: var(--color-3);
  font-weight: 700;
  margin-bottom: 24px;
}
.on-grid .banner_detail_text h2 span {
  color: rgb(3, 100, 5);
  font-size: 25px;
}
.on-grid .banner_detail_text p {
  font-size: 18px;
  color: rgb(0, 49, 143);
  margin-bottom: 0;
}
.on-grid .slider-items-products {
  background: url("../../../../../public/assets/images/Products/banner_img.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 500px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.on-grid .product_text_detail p {
  text-align: justify;
  word-break: break-all;
}
.on-grid .product_text_detail a {
  border-radius: 10px;
  background-color: var(---color-4);
}
.on-grid .product_text_detail a:hover {
  background-color: var(--main-color);
  color: #000;
}

.slider-items-on-grid {
  background: url("../../../../../public/assets/images/on-grid-solar/banner.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 400px;
  max-height: 400px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.slider-items-on-grid .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 65%);
  padding: 10px 0;
}

.bg_images_on-grid {
  background: url("../../../../../public/assets/images/on-grid-solar/Div-section.png");
  background-size: 100% 100%;
}

.inveter_detail_new h4 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
}

.bg_images_product_next .flex_dir-xs {
  flex-direction: row-reverse;
}

@media screen and (max-width: 991px) {
  .on-grid .banner_detail_text h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .on-grid .main_detail_services {
    margin-top: 20px;
  }
  .on-grid .srvices_images {
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .on-grid .product_text_detail p {
    font-size: 14px;
    line-height: 22px;
  }
  .on-grid .banner_detail_text p {
    font-size: 15px;
  }
  .on-grid .banner_detail_text h2,
  .on-grid .inveter_detail_new h4 {
    font-size: 24px;
  }
  .on-grid .main_why_choose_us h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .banner_detail_text .acceptance h2 {
    line-height: 25px;
    font-size: 26px !important;
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 404px) {
  .banner_detail_text .acceptance h2 {
    font-size: 20px !important;
    line-height: 20px !important;
    margin-bottom: 3px !important;
  }
}/*# sourceMappingURL=Products.css.map */