.alcorn-contact-form-services {
    .padding-b-t-50 {
        padding: 50px 0;
    }

    .padding-b-t-20 {
        padding: 20px 0;
    }

    .contact-form {
        background-color: #fff;
        padding: 20px;
        border-radius: 30px;
        box-shadow: -10px 12px 60px 14px rgb(156 191 232);

        .main_detail_services {
            h2 {
                font-size: 35px;
                color: var(--color-3);
                font-weight: bold;

                span {
                    color: rgba(3, 100, 5, 1);
                }
            }

            .pb-4 {
                padding-bottom: 1.5rem !important;
            }
        }

        .input_detail_sectrion {
            display: flex;
            flex-direction: column;

            input {
                margin-bottom: 28px;
                padding: 10px;
                border-radius: 4px;
                width: 100%;
                box-shadow: 0px 5px 2px 0px #c1c1c194;
                height: 52px;
                font-size: 18px;
                border: 1px solid #ebebeb;
                font-weight: bold;
            }

            textarea {
                margin-bottom: 28px;
                padding: 10px;
                border: 0;
                border-radius: 4px;
                width: 100%;
                box-shadow: 0px 3px 2px 3px #c1c1c194;
                height: 100px;
                font-size: 16px;
                font-weight: bold;
            }

            button {
                padding: 10px 20px;
                background-color: rgba(0, 82, 180, 1);
                color: white;
                border: none;
                font-size: 16px;
                font-weight: bold;
                border-radius: 30px;
                cursor: pointer;
                text-transform: uppercase;

                &:hover {
                    background-color: var(--main-color);
                    color: #000;
                }

                span {
                    padding: 5px;
                    font-size: 16px;
                    font-weight: bold;
                    border-radius: 30px;
                    color: rgba(0, 82, 180, 1);
                    background: #fff;
                }
            }
        }
    }

    .Registra_modul_Request {
        max-width: 800px;

        .modal-content {
            border-radius: 0;
            padding: 20px;

            .thank_heading_detail {
                text-align: center;

                h4 {
                    font-size: 25px;
                    color: var(--color-3);
                    font-weight: 800;
                }

                p {
                    font-size: 20px;
                    color: #000;
                    margin-bottom: 0;
                }

                .request_send {
                    padding-top: 30px;

                    .back_color_main {
                        padding: 8px 25px;
                        font-size: 20px;
                        font-weight: bold;
                        color: #fff;
                        background-color: var(--color-3);
                        text-decoration: none;
                        border-radius: 50px;
                        text-align: center;
                        border: 0;
                        max-width: 225px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 auto;

                        &:hover {
                            color: #fff;
                            background-color: rgba(26, 147, 28, 1);
                        }

                        span {
                            i {
                                background-color: #fff;
                                border-radius: 100%;
                                padding: 10px;
                                font-size: 16px;
                                font-weight: bold;
                                color: rgba(26, 147, 28, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}