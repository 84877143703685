// .padding-b-t-50 {
//     padding: 50px 0;
// }

// .padding-b-50 {
//     padding-bottom: 50px;
// }

// .padding-t-50 {
//     padding-top: 50px;
// }

// .padding-b-t-20 {
//     padding: 20px 0;
// }

// .padding-b-t-10 {
//     padding: 20px 0 30px;
// }
.no-padding {
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'geometr415-blk-bt', sans-serif;
    ;
}

:root {
    --main-color: rgba(50, 251, 53, 1);
    --secondary-color: rgba(50, 251, 53, 1);
    --color-3: rgba(0, 48, 144, 1);
    ---color-4: rgba(22, 51, 0, 1);
}

a {
    text-decoration: none;
}

.header-alcorn {

    .bg-primary-black {
        background: #fff;
        border-radius: 10px;

        .header_border_xs {
            .nav-item {
                .dropdown-toggle {
                    &::after {
                        display: none !important;
                    }

                }
            }
        }
    }

    .bg_traprent_fix.sticky {
        background: #00318f;
        padding-bottom: 10px;
    }

    .bg_traprent_fix.sticky .bg-primary-black {
        padding: 0;
    }

    .bg_traprent_fix.sticky .logo_main img {
        width: 110px;
    }

    .bg_traprent_fix.sticky .header_padd_20 {
        padding: 4px 0;
    }
    .bg_traprent_fix.sticky .not-home-page {
        padding: 5px 0 !important;
    }

    .but_start_header {
        display: inline-block;
        border-radius: 6px;
        border: 2px solid var(--main-color);
        background: var(--main-color);
        color: var(--color-3);
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 12px;
        font-size: 15px;
        letter-spacing: 1px;
    }

    .but_start_header:hover {
        border: 2px solid var(--secondary-color);
        background: var(--secondary-color);
    }

    .logo_main img {
        width: 150px;
    }

    .header_padd_20 {
        padding: 15px 0;
    }

    .not-home-page{
        padding: 0px 0 !important;
    }

    /* ==============sosal icon============== */
    .sosal_email_icon a {
        color: #fff;
        font-size: 16px;
        padding-right: 10px;
    }

    .sosal_mid_icon ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .sosal_mid_icon ul li {
        display: inline-block;
        padding: 0 5px;
    }

    .sosal_mid_icon ul li a i {
        color: #fff;
        font-size: 22px;
    }

    .sosal_mid_icon {
        text-align: right;
    }

    /* ==============sosal icon============== */

    .navbar-nav .nav-link {
        color: #333;
        font-size: 16px;
        font-weight: bold;
    }

    .dropend .dropdown-toggle {
        color: var(--color-3);
        margin-left: 1em;
    }

    // .dropdown .dropdown-menu {
    //     display: none;
    // }

    .dropdown:hover>.dropdown-menu,
    .dropend:hover>.dropdown-menu {
        // display: block;
        margin-top: 0.125em;
        margin-left: 0.125em;
    }

    .bg_traprent_fix {
        background: transparent;
        position: fixed;
        z-index: 999;
        width: 100%;
    }

    .navbar-collapse {
        flex-grow: unset;
    }

    .main_nav_button a {
        // max-width: 300px;
        background: var(--color-3);
        padding: 12px 30px !important;
        border-radius: 16px;
        font-size: 15px !important;
        font-weight: 600 !important;
        font-family: 'geometr415-blk-bt', sans-serif;
        color: #fff !important;
    }

    .main_nav_button a:hover {
        background: var(--main-color);
        color: #fff;
    }

    .main_nav_button a i {
        display: none;
    }

    .bg_detail_drop {
        background: rgba(137, 234, 95, 1);
    }

    .icon_drop_sec {
        float: right;
        font-size: 13px;
        line-height: 2;
    }

    .dropend .dropdown-toggle {

        color: #333;
        font-size: 16px;
        font-weight: normal;
        padding: 0 0 7px;
    }

    .dropend .dropdown-toggle:hover {
        color: var(--color-3);
    }

    .bg_detail_drop .dropdown-item:hover {
        color: var(--color-3);
        background: transparent;
    }



    // media query
    @media screen and (min-width: 1200px) {
        .header_border_xs li {
            padding: 0 10px;
        }

    }

    @media screen and (min-width: 992px) {
        .bg-primary-black {
            background: #fff;
            border-radius: 10px;
            padding: 5px 0;
        }

        .dropend:hover>.dropdown-menu {
            position: absolute;
            top: 0;
            left: 100%;
        }

        /* new */
        .bg_detail_drop {
            width: max-content;
            background: rgba(137, 234, 95, 1);
            border-radius: 12px;
            border: 0;
            top: 100%;
            left: 0;
        }


        /* new */
    }

    @media screen and (max-width: 991px) {

        .head_xs_i {
            transform: rotateZ(90deg);
        }

        .header_border_xs li a i {
            float: right;
        }

        .main_banner_headline h3 {
            font-size: 40px;
        }

        .stat-item {
            margin-bottom: 15px;
        }

        .stat-item {
            margin-bottom: 15px;
        }

        .bg_detail_drop {
            padding-top: 0px;
            background: transparent;
            border: 0;
        }

        .bg_detail_drop li a {
            color: #fff;
        }

        .por_detail_section {
            margin-bottom: 15px;
        }

        .bg_traprent_fix {
            background: #345ba6;
            position: sticky;
        }

        .main_nav_button a {
            background: transparent;
            padding-left: 0px !important;
        }

        .bg-primary-black {
            background: transparent;
            padding: 0;
        }

        .main_nav_button a span {
            display: none;
        }

        .main_nav_button a i {
            display: block;
            font-size: 30px;
        }


       

        .pas_detail_section {
            padding-top: 0;
        }

        .slider-items {
            max-height: 700px;
            height: 100%;
        }

        .navbar-nav .nav-link {
            color: #fff;
        }

        /* new */
        .bg_traprent_fix {
            background: #82b7e3 !important;
        }

        .bg_traprent_fix.sticky {
            background: #82b7e3 !important;
        }

        /* new */

    }

    @media screen and (min-width: 768px) {
        .main_why_choose_us h2 {
            font-size: 33px;
        }

        .dropend .dropdown-toggle {
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
    }

    @media screen and (max-width: 767px) {
        .slider-items-srvices {
            min-height: 58vw;
        }

        .main_detail_services {
            margin-top: 20px;
        }

        .srvices_images {
            text-align: center;
        }

        // .flex_dir-xs {
        //     flex-direction: column-reverse;
        // }

        .about_section_detail {
            margin-top: 40px;
        }

    }

    @media screen and (max-width: 576px) {

        .sosal_email_icon,
        .sosal_mid_icon {
            text-align: center;
        }

        .main_why_choose_us h2 {
            font-size: 25px;
        }

        .bg_best_product_1 {
            margin-bottom: 15px;
        }

        .header_padd_20 {
            padding: 3px 0;
        }

        .sosal_email_icon {
            padding-bottom: 10px;
        }

        .por_detail_section {
            margin-bottom: 15px;
        }

        .main_banner_headline h3 {
            color: #fff;
            font-size: 28px;
        }
    }

    @media screen and (max-width: 480px) {
        .main_nav_services {
            padding: 12px 10px;
        }

        .main_nav_contact {
            padding: 12px 15px;
        }
    }
}


// .header-alcorn {

//     .bg_traprent_fix.sticky {
//         background: #00318f;
//         padding-bottom: 10px;
//     }

//     .bg_traprent_fix.sticky {
//         .bg-primary-black {
//             padding: 0;



//         }
//     }

//     .bg_traprent_fix.sticky {
//         .logo_main img {
//             width: 110px;
//         }
//     }

//     .bg_traprent_fix.sticky {
//         .header_padd_20 {
//             padding: 4px 0;
//         }
//     }

//     .bg_traprent_fix {
//         background: transparent;
//         position: fixed;
//         z-index: 999;
//         width: 100%;


//         .bg-primary-black {
//             background: #fff;
//             border-radius: 10px;


//             .left_section_padd {
//                 margin-right: 46px;
//             }

//             .navbar-collapse {
//                 flex-grow: unset;
//             }

//             .navbar-nav {
//                 .nav-link {
//                     color: #333;
//                     font-size: 16px;
//                     font-weight: bold;
//                 }
//             }

//             .main_nav_button {
//                 a {
//                     background: var(--color-3);
//                     padding: 12px 30px;
//                     border-radius: 21px;
//                     font-size: 15px;
//                     font-weight: 600;
//                     font-family: 'Geometr415 Blk BT', sans-serif;
//                     color: #fff;
//                 }
//             }
//         }

//         .header_padd_20 {
//             padding: 15px 0;
//         }

//         .sosal_email_icon {
//             a {
//                 color: #fff;
//                 font-size: 16px;
//                 padding-right: 10px;
//             }
//         }

//         .sosal_mid_icon {
//             ul {
//                 padding: 0;
//                 margin: 0;
//                 list-style: none;
//             }
//         }

//         .sosal_mid_icon {
//             ul {
//                 li {
//                     display: inline-block;
//                     padding: 0 5px;
//                 }
//             }
//         }

//         .sosal_mid_icon {
//             ul {
//                 li {
//                     a {
//                         i {
//                             color: #fff;
//                             font-size: 22px;
//                         }
//                     }
//                 }
//             }
//         }

//         .sosal_mid_icon {
//             text-align: right;
//         }
//     }

//     $color_1: #fff;

//     /* MEdia Query */
//     @media screen and (min-width: 992px) {
//         .bg-primary-black {
//             background: #fff;
//             border-radius: 10px;
//             padding: 5px 0;
//         }

//         .dropend {
//             &:hover {
//                 >.dropdown-menu {
//                     position: absolute;
//                     top: 0;
//                     left: 100%;
//                 }
//             }
//         }

//         .bg_detail_drop {
//             background: rgba(137, 234, 95, 1);
//             border-radius: 12px;
//             border: 0;
//         }

//         .s-right {
//             text-align: right;
//         }

//         .s-left {
//             text-align: left;
//         }

//         .header_border_xs {
//             li {
//                 padding: 0 10px;
//             }
//         }
//     }

//     @media screen and (max-width: 991px) {
//         .head_xs_i {
//             transform: rotateZ(90deg);
//         }

//         .header_border_xs {
//             li {
//                 a {
//                     i {
//                         float: right;
//                     }
//                 }
//             }
//         }

//         .main_banner_headline {
//             h3 {
//                 font-size: 40px;
//             }
//         }

//         .stat-item {
//             margin-bottom: 15px;
//             margin-bottom: 15px;
//         }

//         .bg_detail_drop {
//             background: transparent;
//             border: 0;

//             li {
//                 a {
//                     color: $color_1;
//                 }
//             }
//         }

//         .por_detail_section {
//             margin-bottom: 15px;
//         }

//         .bg_traprent_fix {
//             background: #345ba6;
//             position: sticky;
//         }

//         .main_nav_button {
//             a {
//                 background: transparent;

//                 span {
//                     display: none;
//                 }

//                 i {
//                     display: block;
//                     font-size: 30px;
//                 }
//             }

//             position: absolute;
//             right: 10px;
//             height: 70px;
//             top: 0;
//         }

//         .bg-primary-black {
//             background: transparent;
//             padding: 0;
//         }

//         .left_section_padd {
//             margin-right: 46px;
//         }

//         .pas_detail_section {
//             padding-top: 0;
//         }

//         .slider-items {
//             max-height: 700px;
//             height: 100%;
//         }

//         .navbar-nav {
//             .nav-link {
//                 color: $color_1;
//             }
//         }
//     }

//     @media screen and (min-width: 768px) {
//         .main_why_choose_us {
//             h2 {
//                 font-size: 33px;
//             }
//         }

//         .dropend {
//             .dropdown-toggle {
//                 margin-left: 0.5em;
//             }
//         }
//     }

    // @media screen and (max-width: 767px) {
    //     .alcorn-home-banner .main_banner_headline h3{
    //         font-size: 30px !important;
    //     }
    // }

    @media screen and (max-width: 575px) {
        .header-alcorn .sosal_email_icon{
            display: grid;
        }
        .alcorn-home-why-choose-us .main_why_choose_us h2{
            font-size: 25px !important;
        }
        .alcorn-home-why-choose-us .por_detail_section h3{
            font-size: 16px !important;
        }
        .alcorn-home-about .about_section_detail ul li{
            width: 100% !important;
        }
        .alcorn-home-about .about_section_detail ul{
            flex-direction: column;
        }
        .alcorn-home-about .about_section_detail h2{
            font-size: 26px !important;
        }
        .alcorn-home-about .about_section_detail h6{
            font-size: 20px !important;
        }
        .alcorn-home-banner .main_nav_services,.alcorn-home-banner .main_nav_contact{
            font-size: 14px !important;
            padding: 8px 12px !important;
        }
        .alcorn-home-banner .slider-items{
            background-size: cover !important;
        }
        .alcorn-home-banner .pas_detail_section{
            padding-top: 14px !important;
            padding-bottom: 20px;
        }
        .alcorn-home-banner .main_banner_headline p{
            padding-right: 0px;
        }
        .header-alcorn .logo_main img{
            width: 125px;
        }
        .main_banner_headline{
            h2{
                font-size: 18px;
            }
        }
    }

@media screen and (max-width: 480px) {
    .header-alcorn {
        .sosal_mid_icon{
            ul {
                li {
                    a {
                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .sosal_email_icon {
            a {
                font-size: 12px;
            }
        }
    }

}
.noScroll {
    overflow: hidden !important;
    &::after{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        background: #0000003f;
        z-index: 1;
        
    }
}
// }

@media screen and (max-width: 992px) {
    .dnoneresp{
display: block !important;
    }
    .dnoneresp-mob{
        display: none !important;
    }
}
@media screen and (min-width: 992px) {
    .dnoneresp{
display: none !important;
    }
    .dnoneresp-mob{
        display: block !important;
    }
}




// media Query