.download-section {
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }
    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 100%);
        padding: 10px 0;
    }
    .banner_detail_text h2 {
        font-size: 45px;
        color: var(--color-3);
        font-weight: 800;
    }
    .main_detail_services h2 {
        font-size: 35px;
        color: var(--color-3);
        font-weight: bold;
    }
    .slider-items-download {
        background: url('../../../../../public/assets/images/download/Catalogue-Banner.png');
        width: 100%;
        background-size: 100% 100%;
        min-height: 450px;
        max-height: 450px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
    }

    .main_detail_catalogue {
        margin-bottom: 10px;
        background: #fff;
        box-shadow: 1px 0px 7px 0px #cbcbcb;

        &:hover {
            background: #e7e7e7;
            box-shadow: 1px 0px 7px 0px #cbcbcb;
        }
    }

    .catalogue_detail_img {
        padding: 20px 30px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .main_solar_det {
        padding: 10px 0;
        background-color: rgba(0, 82, 180, 1);
        color: #fff;
        text-align: center;
        font-size: 16px;
        display: flex;
        justify-content: space-evenly;
        cursor: pointer;

        span {
            padding-left: 10px;

            i {
                font-size: 26px;
            }
        }
    }

}