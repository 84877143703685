.store-locator .main_header_sosal.header_padd_20 {
  display: none;
}
.store-locator .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.store-locator .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.store-locator .slider-store-locator {
  width: 100%;
  background-size: 100% 100%;
  min-height: 400px;
  max-height: 400px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.store-locator .main_store_locator {
  text-align: center;
  padding-top: 130px;
}
.store-locator .main_store_locator a {
  background: var(--color-3);
  padding: 10px 45px;
  border-radius: 21px;
  font-size: 20px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
  border: 0;
}
.store-locator .main_store_locator a:hover {
  background: var(--main-color);
  color: #000;
}
.store-locator .main_store_locator h2 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 10px;
}
.store-locator .main_store_locator input {
  font-size: 18px;
  margin-bottom: 10px;
  height: 55px;
  max-width: 400px;
  margin: 20px auto;
}
.store-locator .main_stor_locator {
  justify-content: flex-end;
}
.store-locator .main_detail_section h3 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}
.store-locator .map_detail_main {
  padding: 20px;
  background: rgb(241, 241, 241);
}
.store-locator .main_detail_locetion {
  background: #fff;
  padding: 20px;
}
.store-locator .main_detail_locetion h3 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  padding: 0;
}
.store-locator .main_detail_locetion h3 small {
  font-size: 12px;
  font-weight: normal;
  color: #000;
  float: right;
  padding: 0;
}
.store-locator .main_detail_locetion p {
  font-size: 14px;
  font-weight: normal;
  padding: 0;
}
.store-locator .main_detail_locetion a {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #00318f;
}
.store-locator .searchStore {
  background: var(--color-3);
  padding: 8px 40px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
  border: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  cursor: pointer;
}
.store-locator .locetion_button {
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-top: 20px;
}
.store-locator .locetion_button a:hover {
  background: var(--main-color) !important;
  color: #000 !important;
  border: 1px solid var(--main-color) !important;
}
.store-locator .locetion_button_first {
  padding: 8px 20px;
  font-size: 16px;
  color: #000 !important;
  border: 1px solid #000;
  border-radius: 20px;
  margin-right: 20px;
}
.store-locator .locetion_button_dir {
  padding: 8px 20px;
  font-size: 16px;
  color: #fff !important;
  border: 1px solid var(--color-3);
  background: var(--color-3);
  border-radius: 20px;
  margin-right: 20px;
}
.store-locator .main_locetion_detail {
  height: 100%;
}

@media screen and (max-width: 991px) {
  .store-locator .main_store_locator {
    padding-top: 91px !important;
  }
}/*# sourceMappingURL=StoreLocator.css.map */