@media screen and (min-width: 768px) {
    .main_why_choose_us {
        h2 {

            font-size: 33px;
        }
    }
}



.on-grid {
    .s-left {
        text-align: left;
    }

    .s-right {
        text-align: right;
    }

    .product_text_detail a {
        border-radius: 10px;
        background: rgba(22, 51, 0, 1);
    }

    .product_text_detail a:hover {
        background-color: rgba(26, 147, 28, 1);
    }

    .main_why_choose_us {
        h2 {
            span {
                color: rgba(3, 100, 5, 1);
            }
        }
    }

    .product_text_detail {
        p {
            text-align: justify;
        }
    }

    .main_detail_services {
        ul {
            li {
                padding-bottom: 18px;
                font-size: 16px;
            }

            padding: 20px 10px 0 18px;
        }
    }

    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }

    /* ================Products page==================== */
    .bg_images_product {
        background: url('../../../../../public/assets/images/Products/Div-section-2.png');
        background-size: 100% 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }

    .bg_images_product_next {
        background: url('../../../../../public/assets/images/Products/Div-section-1.png');
        background-size: 100% 100%;
    }

    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 100%);
        padding: 10px 0;

        h2 {
            line-height: 45px;
            font-size: 45px;
            color: var(--color-3);
            font-weight: 700;
            margin-bottom: 24px;

            span {
                color: rgba(3, 100, 5, 1);
                font-size: 25px;
            }
        }

        p {
            font-size: 18px;
            color: rgb(0 49 143);
            margin-bottom: 0;
        }
    }

    .slider-items-products {
        background: url('../../../../../public/assets/images/Products/banner_img.png');
        width: 100%;
        background-size: 100% 100%;
        min-height: 500px;
        height: 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: relative;
    }

    .product_text_detail p {
        text-align: justify;
        word-break: break-all;
    }

    .product_text_detail a {
        border-radius: 10px;
        background-color: var(---color-4);
    }

    .product_text_detail a:hover {
        background-color: var(--main-color);
        color: #000;
    }

    /* ================Products psge==================== */
}

.slider-items-on-grid {
    background: url('../../../../../public/assets/images/on-grid-solar/banner.png');
    width: 100%;
    background-size: 100% 100%;
    min-height: 400px;
    max-height: 400px;
    height: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    position: relative;

    .banner_detail_text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        background: linear-gradient(90deg, #fff 0%, transparent 65%);
        padding: 10px 0;
    }
}

.bg_images_on-grid {
    background: url('../../../../../public/assets/images/on-grid-solar/Div-section.png');
    background-size: 100% 100%;
}

.inveter_detail_new {
    h4 {
        font-size: 32px;
        color: var(--color-3);
        font-weight: 800;
    }
}

.bg_images_product_next .flex_dir-xs {
    flex-direction: row-reverse;
}

// .bg_images_product_next .s-right{
//     text-align: left;
// }
@media screen and (max-width: 991px) {
    .on-grid {
        .banner_detail_text h2 {
            font-size: 30px;
        }
    }
}

@media screen and (max-width: 767px) {


    .on-grid {
        .main_detail_services {
            margin-top: 20px;
        }

        .srvices_images {
            text-align: center;
        }
    }
}

@media screen and (max-width: 576px) {
    .on-grid {
        .product_text_detail {
            p {
                font-size: 14px;
                line-height: 22px;
            }
        }

        .banner_detail_text {
            p {
                font-size: 15px;
            }

        }

        .banner_detail_text h2,
        .inveter_detail_new h4 {
            font-size: 24px;
        }

        .main_why_choose_us h2 {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 767px) {
    .banner_detail_text .acceptance h2 {
        line-height: 25px;
        font-size: 26px !important;
        margin-bottom: 5px !important;
    }
}

@media screen and (max-width: 404px) {
    .banner_detail_text .acceptance h2 {
        font-size: 20px !important;
        line-height: 20px !important;
        margin-bottom: 3px !important;
    }
}