.load-calculator {
    .main_header_sosal.header_padd_20 {
        display: none;
    }

    .bg_traprent_fix {
        background: transparent;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;

        &.sticky {
            padding-top: 10px;
            background: #00318f;
            padding-bottom: 10px;
            position: fixed;
        }
    }
    .card-fans-cooler {
        border: 1px solid #ccc;
        border-radius: 10px;
        margin-bottom: 15px;

        .card-header {
            color: #fff;
            background: var(--color-3);
            font-size: 18px;
            font-weight: 900;
            padding: 10px;
            border-radius: 10px 10px 0 0;
        }

        .table-responsive {
            padding: 0 15px;

            table {
                border-collapse: separate;
                border-spacing: 0 .5rem;
                margin-bottom: 5px;

                th {
                    font-size: 18px;
                    font-weight: 900;
                    color: var(--color-3);
                    text-align: center;
                    border-bottom: 0px;
                }

                td {
                    text-align: center;
                    background: #eceff4;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }
            }
        }
    }

    .main_detail_bg {
        padding: 20px;
        margin: 20px 0;
        box-shadow: 1px 0px 7px 0px #cbcbcb;
    }

    .input-group {
        width: 120px;
        margin: 0 auto;
    }
    .input-group-footer {
        width: unset !important;
        margin: 0 auto;
    }

    .btn-minus,
    .btn-plus {
        width: 35px;
    }

    .btn-minus {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: var(--color-3);
        border: 0;
        font-weight: bold;
    }

    .btn-plus {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background: var(--color-3);
        border: 0;
        font-weight: bold;
    }

    .requirement_main {
        h4 {
            color: #000;
            font-size: 25px;
            font-weight: 900;
            padding-left: 10px;

            span {
                color: #fff;
                height: 30px;
                width: 30px;
                border-radius: 5px;
                background: var(--color-3);
                font-size: 25px;
                font-weight: 900;
                display: inline-block;
                text-align: center;
                margin-right: 10px;
            }
        }
    }

    .main_input_detail {
        label {
            color: var(--color-3);
            font-size: 16px;
            padding-bottom: 5px;
            font-weight: 900;
        }

        input {
            height: 45px;
            border-radius: 6px;
            font-weight: bold;
        }

        select {
            height: 45px;
            border-radius: 6px;
            font-weight: bold;
        }

        span {
            color: red;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .main_Calculate_button {
        button {
            background: var(--color-3);
            padding: 12px 30px;
            border-radius: 16px;
            font-size: 15px;
            font-weight: 600;
            font-family: 'geometr415-blk-bt', sans-serif;
            color: #fff;

            &:hover {
                background: var(--main-color);
                color: #fff;
            }
        }
    }

}