.load-calculator .main_header_sosal.header_padd_20 {
  display: none;
}
.load-calculator .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.load-calculator .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.load-calculator .card-fans-cooler {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 15px;
}
.load-calculator .card-fans-cooler .card-header {
  color: #fff;
  background: var(--color-3);
  font-size: 18px;
  font-weight: 900;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}
.load-calculator .card-fans-cooler .table-responsive {
  padding: 0 15px;
}
.load-calculator .card-fans-cooler .table-responsive table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-bottom: 5px;
}
.load-calculator .card-fans-cooler .table-responsive table th {
  font-size: 18px;
  font-weight: 900;
  color: var(--color-3);
  text-align: center;
  border-bottom: 0px;
}
.load-calculator .card-fans-cooler .table-responsive table td {
  text-align: center;
  background: #eceff4;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.load-calculator .main_detail_bg {
  padding: 20px;
  margin: 20px 0;
  box-shadow: 1px 0px 7px 0px #cbcbcb;
}
.load-calculator .input-group {
  width: 120px;
  margin: 0 auto;
}
.load-calculator .input-group-footer {
  width: unset !important;
  margin: 0 auto;
}
.load-calculator .btn-minus,
.load-calculator .btn-plus {
  width: 35px;
}
.load-calculator .btn-minus {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: var(--color-3);
  border: 0;
  font-weight: bold;
}
.load-calculator .btn-plus {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: var(--color-3);
  border: 0;
  font-weight: bold;
}
.load-calculator .requirement_main h4 {
  color: #000;
  font-size: 25px;
  font-weight: 900;
  padding-left: 10px;
}
.load-calculator .requirement_main h4 span {
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: var(--color-3);
  font-size: 25px;
  font-weight: 900;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.load-calculator .main_input_detail label {
  color: var(--color-3);
  font-size: 16px;
  padding-bottom: 5px;
  font-weight: 900;
}
.load-calculator .main_input_detail input {
  height: 45px;
  border-radius: 6px;
  font-weight: bold;
}
.load-calculator .main_input_detail select {
  height: 45px;
  border-radius: 6px;
  font-weight: bold;
}
.load-calculator .main_input_detail span {
  color: red;
  font-size: 12px;
  font-weight: bold;
}
.load-calculator .main_Calculate_button button {
  background: var(--color-3);
  padding: 12px 30px;
  border-radius: 16px;
  font-size: 15px;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
}
.load-calculator .main_Calculate_button button:hover {
  background: var(--main-color);
  color: #fff;
}/*# sourceMappingURL=LoadCalculator.css.map */