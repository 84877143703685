@charset "UTF-8";
.design-solar-product .form-check-input-new:checked {
  background-color: rgb(0, 48, 144);
  border-color: rgb(0, 48, 144);
}
.design-solar-product .form-check-input-new {
  width: 34px !important;
  height: 34px !important;
}
.design-solar-product .main_extended_option {
  display: flex;
  align-items: center;
  position: relative;
}
.design-solar-product .form-check-input-new:checked::before {
  content: "✓";
  display: block;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
}
.design-solar-product .form-check-new .form-check-label-new {
  color: rgb(33, 33, 33);
  padding-left: 10px;
}
.design-solar-product .form-check-new {
  display: inline-flex;
  align-items: center;
  margin-right: 1em;
}
.design-solar-product .custom-date-picker {
  position: relative;
  width: 100%;
}
.design-solar-product .custom-date-picker svg {
  position: absolute;
  top: 7px;
  right: 10px;
  color: #7b7b7b;
}
.design-solar-product .custom-date-picker .react-datepicker-wrapper {
  width: 100%;
}
.design-solar-product .custom-date-picker .react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid #ced4da !important;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
}
.design-solar-product .card-fans-details {
  background: #fff;
}
.design-solar-product .card-fans-details .customize-header {
  color: #fff;
  background: var(--color-3);
  font-size: 18px;
  font-weight: 900;
  padding: 10px;
  border-radius: 0;
}
.design-solar-product .main_input_detail label {
  color: var(--color-3);
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 900;
}
.design-solar-product .bg_images_se {
  background: url("../../../../../public/assets/images/srvices/Background-Image.png");
  background-size: 100% 100%;
}
.design-solar-product .main_detail_persanal {
  padding: 20px;
}
.design-solar-product .main_input_detail .sectopn_class {
  height: 45px;
  border-radius: 6px;
  font-size: 15px;
  border: 1px solid #ccc;
  width: 100%;
}
.design-solar-product .options {
  display: flex;
  align-items: center;
}
.design-solar-product .options input[type=radio] {
  display: none;
}
.design-solar-product .options label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}
.design-solar-product .options label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-right: 5px;
}
.design-solar-product .options input[type=radio]:checked + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: green;
  margin-right: 5px;
}
.design-solar-product .form-control-technology {
  padding: 10px;
  border: 1px solid rgba(193, 193, 193, 0.5803921569);
  border-radius: 4px;
  width: 100%;
  height: 100px;
  font-size: 14px;
}
.design-solar-product .main_input_detail_button {
  background: var(--color-3);
  padding: 10px 30px;
  border-radius: 16px;
  font-size: 18px;
  border: 0;
  font-weight: 600;
  font-family: "geometr415-blk-bt", sans-serif;
  color: #fff;
}
.design-solar-product .main_input_detail_button:hover {
  background: var(--main-color);
  color: #fff;
}
.design-solar-product .slider-items-SPGS {
  background: url("../../../../../public/assets/images/download/banner_SPGS.png");
  width: 100%;
  background-size: 100% 100%;
  min-height: 450px;
  max-height: 450px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: relative;
}
.design-solar-product .file-input {
  padding-bottom: 10px;
  position: relative;
}
.design-solar-product .folder_main_bro {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid rgb(224, 224, 224);
  color: #919191;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.design-solar-product .file-input > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.design-solar-product .main_header_sosal.header_padd_20 {
  display: none;
}
.design-solar-product .bg_traprent_fix {
  background: transparent;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
}
.design-solar-product .bg_traprent_fix.sticky {
  padding-top: 10px;
  background: #00318f;
  padding-bottom: 10px;
  position: fixed;
}
.design-solar-product .slider-items-srvices {
  background: url("../../../../../public/assets/images/srvices/srvices.png");
  width: 100%;
  min-height: 500px;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
}
.design-solar-product .banner_detail_text {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background: linear-gradient(90deg, #fff 0%, transparent 100%);
  padding: 10px 0;
}
.design-solar-product .main_why_choose_us h2 {
  font-size: 32px;
  color: var(--color-3);
  font-weight: 800;
}
.design-solar-product .banner_detail_text p {
  font-size: 18px;
  color: rgb(0, 49, 143);
  margin-bottom: 0;
}
.design-solar-product .banner_detail_text h2 {
  font-size: 45px;
  color: var(--color-3);
  font-weight: 800;
}
.design-solar-product .banner_detail_text h2 span {
  color: rgb(3, 100, 5);
}
.design-solar-product .custom-mobile-code .iti--allow-dropdown {
  width: 100%;
}

.multiple-img-box {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 6px;
}
.multiple-img-box .absolute-img {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}
.multiple-img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.image-preview-container {
  flex-wrap: wrap;
}/*# sourceMappingURL=DesignSolarProduct.css.map */